import React from "react";
import commandPromptIcon from "../media/commandPromptIcon.png";
import commandPromptMinMaxX from "../media/commandPromptMinMaxX.png";
import { Container, Row, Col } from "reactstrap";
import Image from "react-bootstrap/Image";

const CommandPrompt = () => {
  return (
    <Container className="bg-window rounded-top-corners">
      <Row className="py-1 align-items-center">
        <Col xs="1" className="text-left">
          <Image fluid="true" src={commandPromptIcon} />
        </Col>
        <Col xs="10" className="text-left px-1">
          Command Prompt
        </Col>
        <Col xs="1" className="text-right">
          <Image fluid="true" src={commandPromptMinMaxX} />
        </Col>
      </Row>
    </Container>
  );
};

export default CommandPrompt;
