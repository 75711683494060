import { useState, Fragment } from "react";
import { Modal, ModalBody, Container, Row, Col } from "reactstrap";
import TypeWriterEffect from "react-typewriter-effect";
import { Form, redirect, useParams, useNavigation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import "./Styles.css";
import { retrieveTeamInfo, createTeamInfo } from "../../_util/api";
import CommandPrompt from "../components/CommandPrompt";

const Leader2 = () => {
  const params = useParams();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === "submitting";
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div");

  return (
    <Fragment>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="Please enter the names of all your team members (including yourself)."
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
        </ModalBody>
        <ModalBody className="bg-console text-center">
          <Form
            className="appear-3"
            method="post"
            action={`/escapin-not-stirred/leader2/${params.event_id}/${params.team_id}`}
          >
            <input
              type="text"
              name="Team_Members"
              id="team_members"
              className="team-info-form__input text-center"
            />
            <button
              className="instruction-text__button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "..." : "Enter"}
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Leader2;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const post = {
    Event_ID: params.event_id,
    Team_ID: params.team_id,
    Team_Members: formData.get("Team_Members"),
  };
  // try {
  const team_update_results = await createTeamInfo(post);
  // } catch (err) {
  //     if (err.status === 500) {
  //         return err;  //this prevents a redirect
  //     }
  //     throw err;
  // }
  return redirect(
    "/escapin-not-stirred/leader3/" + post.Event_ID + "/" + post.Team_ID
  );
}
