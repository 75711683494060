import React, { useState, useEffect } from "react";
import FormUpdateEventData1 from "../components/FormUpdateEventData1";
import FormUpdateEventData2 from "../components/FormUpdateEventData2";
import {
  useParams,
  useLoaderData,
  useNavigate,
  useNavigation,
  Link,
} from "react-router-dom";
import { Container, Grid, Box, Paper, Typography } from "@mui/material";
import { usePusher } from "../../_context/PusherContext";

const Event = (props) => {
  const eventData = useLoaderData();
  console.log(
    eventData.startUrl,
    eventData.zoomUrl,
    eventData.organizer,
    eventData.status
  );
  const [conflictingEvents, setConflictingEvents] = useState(false);
  const [shortNotice, setShortNotice] = useState(false);
  const eventNotes = JSON.parse(eventData.notes);

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const navigate = useNavigate();

  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  // console.log(
  //   "My eventNotes are as follows: conflictingEvents: " +
  //     eventNotes.conflictingEvents +
  //     " availableTimeslots: " +
  //     eventNotes.availableTImeslots
  // );

  useEffect(() => {
    if (eventNotes.conflictingEvents) {
      setConflictingEvents(true);
    }
    if (eventNotes.shortNotice) {
      setShortNotice(true);
    }
    //////// PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("ver_scheduling", (data) => {
      if (
        data["eventId"] === params.eventId.toUpperCase() &&
        data["pushType"] === "refresh"
      ) {
        navigate("/event/" + params.eventId); // I am navigating (redirecting) to the same page we were at so that I effectively refresh the data presented
      }
    });
    return () => {
      pusher.unsubscribe("ver_pusher");
    };
    //////// PUSHER /////////
  }, []);

  const params = useParams();

  return (
    <Container>
      <Grid container>
        {eventData.status === "initialized" && (
          <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
            <Box sx={{ maxWidth: 500 }}>
              <Paper sx={{ p: 2 }} elevation={12}>
                <Box textAlign={"center"}>
                  <Typography variant={"h6"}>
                    <span style={{ fontWeight: "bolder" }}>Game:</span>{" "}
                    {eventData.game}
                  </Typography>
                  <Typography variant={"h6"}>
                    <span style={{ fontWeight: "bolder" }}>Date:</span>{" "}
                    {eventData.dateAndTime} {eventData.timezone}
                  </Typography>
                </Box>
                {shortNotice && (
                  <FormUpdateEventData2
                    eventNotes={eventNotes}
                    shortNotice={true}
                  />
                )}
                {!shortNotice && conflictingEvents && (
                  <FormUpdateEventData2
                    eventNotes={eventNotes}
                    conflictingEvents={true}
                  />
                )}
                <Box mb={3} />
                <FormUpdateEventData1
                  conflictingEvents={conflictingEvents}
                  shortNotice={shortNotice}
                />
              </Paper>
            </Box>
          </Grid>
        )}
        {eventData.status === "primed" && (
          <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
            <Box sx={{ maxWidth: 500 }}>
              <Paper sx={{ p: 2 }} elevation={12}>
                <Box textAlign={"center"}>
                  <Typography variant={"subtitle1"}>
                    <span style={{ fontWeight: "bolder" }}>Game:</span>{" "}
                    {eventData.game}
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    <span style={{ fontWeight: "bolder" }}>Date:</span>{" "}
                    {eventData.dateAndTime} {eventData.timezone}
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    <span style={{ fontWeight: "bolder" }}>Est. Size:</span>{" "}
                    {eventData.size} players
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    <span style={{ fontWeight: "bolder" }}>Hosting:</span>{" "}
                    {eventData.type}
                  </Typography>
                </Box>{" "}
                {eventData.type == "VER-Hosted" && (
                  <Box>
                    <br />
                    <p>
                      Thank you for scheduling your VER event! Please complete
                      the ${eventData.dollarsOwed} scheduling payment. This is
                      nonrefundable and pays for up to your first 5
                      participants. A link to this payment page has been emailed
                      to you. Once payment is complete, you will receive an
                      email with the links you'll need for the event along with
                      simple instructions to distribute to your group. <br />
                      <br />
                      <Box textAlign={"center"}>
                        <strong>Invoice ID: </strong>
                        {eventData.eventId}
                      </Box>
                    </p>
                    <iframe
                      src={
                        `https://saorsaver.securepayments.cardpointe.com/pay?mini=1&total=${eventData.dollarsOwed}&invoice=` +
                        params.eventId
                      }
                      frameborder="0"
                      width="100%"
                      height="500px"
                    ></iframe>
                  </Box>
                )}
                {eventData.type == "Self-Hosted" && (
                  <Box>
                    <br />
                    <p>
                      Thank you for scheduling your VER event! Please complete
                      the ${eventData.dollarsOwed} scheduling payment. This is
                      nonrefundable and pays for up to your first 2
                      participants. A link to this payment page has been emailed
                      to you. <br />
                      <br /> Once payment is complete, you'll receive an email
                      containing the links you'll need for the event, along with
                      simple instructions to distribute to your group. There'll
                      also be a comprehensive set of "how to" instructions for
                      setting up your video meeting and hosting your event.
                      It'll take less than 5 minutes to get everything set up!{" "}
                      <br />
                      <br />
                      <Box textAlign={"center"}>
                        <strong>Invoice ID: </strong>
                        {eventData.eventId}
                      </Box>
                    </p>
                    <iframe
                      src={
                        `https://saorsaver.securepayments.cardpointe.com/pay?mini=1&total=${eventData.dollarsOwed}&invoice=` +
                        params.eventId
                      }
                      frameborder="0"
                      width="100%"
                      height="500px"
                    ></iframe>
                  </Box>
                )}
                <br />
              </Paper>
            </Box>
          </Grid>
        )}
        {eventData.status === "scheduled" &&
          eventData.type === "VER-Hosted" && (
            <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
              <Box sx={{ maxWidth: 750 }}>
                <Paper sx={{ p: 2 }} elevation={12}>
                  <Box textAlign={"center"}>
                    <h3>Your VER-Hosted Event Has Been Scheduled!</h3>
                    <p>
                      All of the following information has also been sent to you
                      in an email.
                    </p>
                  </Box>

                  <h6>
                    At your earliest convenience, please:
                    <ol type="a">
                      <li>Copy all text within the dashed lines below</li>
                      <li>Paste it into an e-calendar invite</li>
                      <li>
                        Configure the datetime of the invite to{" "}
                        {eventData.dateAndTime} {eventData.timezone}
                      </li>
                      <li>Send the e-calendar invite to your entire group</li>
                    </ol>
                  </h6>
                  <h6>-----------------------------------------</h6>
                  <h6>
                    You're cordially invited to the "{eventData.game}" Virtual
                    Escape Room adventure! On {eventData.dateAndTime}{" "}
                    {eventData.timezone}, please use your laptop, desktop
                    computer, or tablet to complete steps 1 - 4 below (mobile
                    devices will work, but won't provide the best user
                    experience so we don't recommend using them):
                  </h6>
                  <ol>
                    <li>
                      Everyone calls into{" "}
                      <a href={`${eventData.zoomUrl}`}>this Zoom meeting</a>
                      <ul>
                        <li>
                          If/when prompted, please use the Zoom Desktop Client
                          (as opposed to the Zoom Web Browser) for the best user
                          experience
                        </li>
                      </ul>
                    </li>
                    <li>
                      Your group will be divided into teams and placed into
                      Breakout Rooms by a member of the VER staff
                      <ul>
                        <li>
                          We can divide teams randomly at the start of the event
                          (recommended to save time), or your group can decide
                          ahead of time what you'd like the breakdown to be
                        </li>
                      </ul>
                    </li>
                    <li>
                      Each person clicks on{" "}
                      <a href={`${eventData.startUrl}`}>this Escape Room URL</a>
                      <ul>
                        <li>
                          Interested in learning about how to play the game or
                          what to expect? You can navigate to this link ahead of
                          the event to find out, but this is not required
                        </li>
                      </ul>
                    </li>
                    <li>
                      Everyone will follow a series of simple instructions
                      displayed in the Escape Room URL (including choosing a
                      Team Leader and watching an instructional video) and begin
                      the game when they are ready!
                    </li>
                  </ol>
                  <h6>-----------------------------------------</h6>
                </Paper>
              </Box>
            </Grid>
          )}
        {eventData.status === "scheduled" &&
          eventData.type === "Self-Hosted" && (
            <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
              <Box sx={{ maxWidth: 750 }}>
                <Paper sx={{ p: 2 }} elevation={12}>
                  <Box textAlign={"center"}>
                    <h3>Your Self-Hosted Event Has Been Scheduled!</h3>
                    <p>
                      All of the following information has also been sent to you
                      in an email.
                    </p>
                  </Box>

                  <h6>
                    At your earliest convenience, please:
                    <ol type="a">
                      <li>
                        Create a virtual meeting for your event
                        <ul>
                          <li>
                            We recommend using Zoom, but any platform that
                            supports Breakout Rooms can be used (such as Teams
                            or WebEx)
                          </li>
                        </ul>
                      </li>
                      <li>Copy all text within the dashed lines below</li>
                      <li>Paste it into an e-calendar invite</li>
                      <li>
                        Paste the Virtual Meeting's URL at the end of step 1,
                        replacing the "[paste meeting URL here]" text
                      </li>
                      <li>
                        Configure the date & time of the invite to{" "}
                        {eventData.dateAndTime} {eventData.timezone}
                      </li>
                      <li>Send the e-calendar invite to your entire group</li>
                      <li>
                        Watch{" "}
                        <a href="https://youtu.be/YLQ-PK36W9s">
                          this 3-minute video
                        </a>{" "}
                        to understand best practices in hosting your VER event
                      </li>
                    </ol>
                  </h6>
                  <h6>-----------------------------------------</h6>
                  <h6>
                    You're cordially invited to the "{eventData.game}" Virtual
                    Escape Room adventure! On {eventData.dateAndTime}{" "}
                    {eventData.timezone}, please use your laptop, desktop
                    computer, or tablet to complete steps 1 - 4 below (mobile
                    devices will work, but won't provide the best user
                    experience so we don't recommend using them):
                  </h6>
                  <ol>
                    <li>
                      Everyone calls into this virtual meeting: [paste meeting
                      URL here]
                      <ul>
                        <li>
                          If/when prompted, please use the meeting platform's
                          Desktop Client (as opposed to the platform's Web
                          Browser version) for the best user experience
                        </li>
                      </ul>
                    </li>
                    <li>
                      Your group will be divided into teams and placed into
                      Breakout Rooms by your event coordinator
                      <ul>
                        <li>
                          Teams can be divided randomly at the start of the
                          event (recommended to save time), or your group can
                          decide ahead of time what you'd like the breakdown to
                          be
                        </li>
                      </ul>
                    </li>
                    <li>
                      Each person clicks on{" "}
                      <a href={`${eventData.startUrl}`}>this Escape Room URL</a>
                      <ul>
                        <li>
                          Interested in learning about how to play the game or
                          what to expect? You can navigate to this link ahead of
                          the event to find out, but this is not required
                        </li>
                      </ul>
                    </li>
                    <li>
                      Everyone will follow a series of simple instructions
                      displayed in the Escape Room URL (including choosing a
                      Team Leader and watching an instructional video) and begin
                      the game when they are ready!
                    </li>
                  </ol>
                  <h6>-----------------------------------------</h6>
                </Paper>
              </Box>
            </Grid>
          )}
        {eventData.status === "complete" && (
          <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
            <Box sx={{ maxWidth: 750 }}>
              <Paper sx={{ p: 2 }} elevation={12}>
                <Typography textAlign={"center"}>
                  Thank you for playing VER!
                </Typography>
                <Box>
                  <br />
                  <p>
                    Please complete the ${eventData.dollarsOwed} final payment.
                    A link to this payment page has been emailed to you. <br />
                    <br /> Once payment is complete, you'll receive an emailed
                    receipt.
                    <br />
                    <br />
                    <Box textAlign={"center"}>
                      <strong>Invoice ID: </strong>
                      {eventData.eventId}
                    </Box>
                  </p>
                  <iframe
                    src={
                      `https://saorsaver.securepayments.cardpointe.com/pay?mini=1&total=${eventData.dollarsOwed}&invoice=` +
                      params.eventId
                    }
                    frameborder="0"
                    width="100%"
                    height="500px"
                  ></iframe>
                </Box>
              </Paper>
            </Box>
          </Grid>
        )}
        {eventData.status === "closed" && (
          <Grid sx={{ margin: "auto", maxWidth: "100vw" }} item>
            <Box sx={{ maxWidth: 750 }}>
              <Paper sx={{ p: 2 }} elevation={12}>
                <Typography textAlign={"center"}>
                  Thank you for playing VER!
                  <br />
                  All payment for this event has been completed. We hope to host
                  you again soon!
                </Typography>
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Event;
