import React from "react";
import TypeWriterEffect from "react-typewriter-effect";

import { Container, Row, Col } from "reactstrap";
import Image from "react-bootstrap/Image";
import CommandPrompt from "./CommandPrompt";

const InstructionText = (props) => {
  return (
    <>
      <CommandPrompt />
      <div
        className="bg-console p-2 instruction-text__item align-items-start"
        style={{
          height: "25vh",
          overflowY: "auto",
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
          border: "solid rgb(171, 11, 11)",
        }}
      >
        <TypeWriterEffect
          startDelay={100}
          cursorColor="white"
          textStyle={{
            fontWeight: 500,
            fontSize: "1em",
          }}
          text={props.typeText}
          typeSpeed={50}
        />
      </div>
    </>
  );
};

export default InstructionText;
