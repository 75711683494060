import React, { useEffect } from "react";
import { RetrieveLeaderboard } from "../../_loaders/RetrieveLeaderboard";
import { useLoaderData, useParams, useRevalidator } from "react-router-dom";
import { Table } from "reactstrap";
import Timer from "../../_components/Timer";
import { usePusher } from "../../_context/PusherContext";

const Leaderboard = () => {
  let timestamp = new Date(Date.now());
  console.log(timestamp + " - Leaderboard component just rerendered");

  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  const loaderData = useLoaderData();
  console.log(loaderData);

  const refresher = useRevalidator();

  const params = useParams();

  useEffect(() => {
    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      if (
        data["event_id"] === params.event_id &&
        data["push_type"] === "leaderboard_update"
      ) {
        refresher.revalidate();
      }
    });
    return () => {
      pusher.unsubscribe("ver_pusher");
    };
    // PUSHER /////////
  }, []);

  let rank = 0;
  const getRank = (previousRank) => {
    rank++;
    return rank;
  };

  const DisplayData = loaderData.map((info) => {
    if (info.completionTime != null) {
      if (params.teamId != info.teamId) {
        return (
          <tr key={Math.random().toString()}>
            <th scope="row">{getRank(rank)}</th>
            <td>{info.teamName}</td>
            <td>{info.teamMembers}</td>
            <td>DONE!</td>
            <td>{info.scoreTime}</td>
          </tr>
        );
      } else {
        return (
          <tr
            key={Math.random().toString()}
            style={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "bolder",
            }}
          >
            <th scope="row">{getRank(rank)}</th>
            <td>{info.teamName}</td>
            <td>{info.teamMembers}</td>
            <td>DONE!</td>
            <td>{info.scoreTime}</td>
          </tr>
        );
      }
    } else {
      if (params.teamId != info.teamId) {
        return (
          <tr key={Math.random().toString()}>
            <th scope="row">{getRank(rank)}</th>
            <td>{info.teamName}</td>
            <td>{info.teamMembers}</td>
            <td>{info.challengeId}</td>
            <td>
              <Timer accumulatedSeconds={info.accumulatedSeconds} />
            </td>
          </tr>
        );
      } else {
        return (
          <tr
            key={Math.random().toString()}
            style={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "bolder",
            }}
          >
            <th scope="row">{getRank(rank)}</th>
            <td>{info.teamName}</td>
            <td>{info.teamMembers}</td>
            <td>{info.challengeId}</td>
            <td>
              <Timer accumulatedSeconds={info.accumulatedSeconds} />
            </td>
          </tr>
        );
      }
    }
  });

  return (
    <Table style={{ color: "white", border: "solid rgb(171, 11, 11)" }}>
      <thead style={{ backgroundColor: "rgb(171, 11, 11)" }}>
        <tr style={{ borderRadius: 20 }}>
          <th>Rank</th>
          <th>Team</th>
          <th>Members</th>
          <th>Level</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody style={{ backgroundColor: "black" }}>{DisplayData}</tbody>
    </Table>
  );
};

export default Leaderboard;

export function loader({ params }) {
  return RetrieveLeaderboard(params.eventId);
}
