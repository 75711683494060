import React, { useState, useEffect, useContext, Fragment } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import "./Styles.css";
import { retrieveTeamInfo } from "../../_util/api";
import Timer from "../../_components/Timer";
import { Outlet } from "react-router-dom";
import useSound from "use-sound";
import Image from "react-bootstrap/Image";
import { usePusher } from "../../_context/PusherContext";
import { motion, AnimatePresence, useAnimationControls } from "framer-motion";
import InstructionText from "../components/InstructionText";
import hintModalRevealSound from "../media/hintModalRevealSound.mp3";
import riddle1 from "../media/riddle1.png";
import riddle2 from "../media/riddle2.png";
import riddle3 from "../media/riddle3.png";
import riddle4 from "../media/riddle4.png";

const Sleuther3 = () => {
  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  console.log("Sleuther 3 evaluated by React");

  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const params = useParams();
  const myAppRef = document.getElementById("nefarious-villain-console"); // this is for the typewriter component

  const [playHint] = useSound(hintModalRevealSound);

  const [leaderboardTrigger, setLeaderboardTrigger] = useState(true); // hijack the boolean here
  const [timerTime, setTimerTime] = useState(loaderData.Accumulated_Seconds);
  const [revealHint, setRevealHint] = useState(false);
  const [hintInstructions, setHintInstructions] = useState();
  const [hint1Display, setHint1Display] = useState("");
  const [hint2Display, setHint2Display] = useState("");
  const [hint3Display, setHint3Display] = useState("");
  const [isIncorrect, setIsIncorrect] = useState(false);

  const clickHandler = () => {
    setRevealHint(!revealHint);
    playHint();
  };

  useEffect(() => {
    let hint_instructions = "";
    if (loaderData.Hint_ID == "0") {
      hint_instructions =
        "HINTS USED: 0/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your first hint will add 2 MINUTES to your score.";
    } else if (loaderData.Hint_ID == "1") {
      hint_instructions =
        "HINTS USED: 1/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your second hint will add 3 MINUTES to your score.";
    } else if (loaderData.Hint_ID == "2") {
      hint_instructions =
        "HINTS USED: 2/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your third and final hint will add 5 MINUTES to your score.";
    } else {
      hint_instructions =
        "HINTS USED: 3/3 There are no more hints available to buy. Read through the ones you've been given and you'll be able to solve this challenge.";
    }
    setHintInstructions(hint_instructions);
  }, [loaderData]);

  useEffect(() => {
    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      console.log(
        data["event_id"] +
          ":" +
          loaderData.Event_ID +
          ",   " +
          data["team_id"] +
          ":" +
          loaderData.Team_ID
      );
      if (
        data["event_id"] === loaderData.Event_ID.toString() &&
        data["team_id"] == loaderData.Team_ID.toString()
      ) {
        if (data["push_type"] === "sleuther_reroute") {
          // This occurs when this team's Team Leader submits a correct response
          console.log("Pusher sessionID = " + pusher["sessionID"].toString());
          navigate(data["url"]);
        }
      }
    });
    return () => {
      pusher.unsubscribe("ver_pusher");
    };
    // PUSHER /////////
  }, []);

  return (
    <Fragment>
      <Container fluid={true} style={{ backgroundColor: "#333232" }}>
        <Row>
          <Col md="8" className="ver-top-row text-white">
            <motion.div
              initial={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.1, duration: 0.5 }}
            >
              <InstructionText typeText="Aren't you so smart? I have to admit I'm somewhat impressed. But what have you learned? You'll have to  guess the four-digit passcode that will unlock your next clue. This time, wrong answers will cost you 2 MINUTES. See if you can make order out of these pictures." />
            </motion.div>
          </Col>
          <Col
            md="4"
            className="ver-top-row text-center text-white background-gray"
          >
            <Row>
              <Col>
                <h1
                  className="p-1"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 15,
                    fontSize: 60,
                    color: "#0bdf08",
                  }}
                >
                  {/* <Timer accumulatedSeconds={timerTime} /> */}
                </h1>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                className="text-center"
                style={{
                  borderRadius: 15,
                  boxShadow: "5px 5px black",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <h3 className="mb-0">Team ID:</h3>
                <motion.h1
                  whileHover={{
                    scale: 1.1,
                    textShadow: "0px 0px 8px rgb(255,255,255)",
                  }}
                  className="mt-0"
                >
                  {loaderData.Team_ID}
                </motion.h1>
              </Col>
              <Col>
                <button
                  className="instruction-text__button"
                  onClick={clickHandler}
                >
                  Hint
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center text-center text-white">
          <Col md="8" className="ver-bottom-row">
            <Row className={`cocktail-height`}>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle1}
                />
              </Col>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle2}
                />
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row className={`cocktail-height`}>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle3}
                />
              </Col>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle4}
                />
              </Col>
            </Row>
          </Col>
          <Col md="4" className="ver-bottom-row scroll-x overflow-y-scroll">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Offcanvas
        backdrop={false}
        direction="end"
        fade={false}
        scrollable
        isOpen={revealHint}
      >
        <OffcanvasHeader onClick={clickHandler}>
          <Container>
            <Row>
              <Col>
                <h3 className="instruction-text__button no-btn">Close</h3>
              </Col>
              <Col>
                <h1 id="hint-timer">
                  <Timer accumulatedSeconds={timerTime} />
                </h1>
              </Col>
            </Row>
          </Container>
        </OffcanvasHeader>
        <OffcanvasBody>
          <strong>{hintInstructions}</strong>
          <h6>{hint1Display}</h6>
          <h6>{hint2Display}</h6>
          <h6>{hint3Display}</h6>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Sleuther3;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}
