import React from "react";
import { Box, Typography } from "@mui/material";

const LockedInfo = (props) => {
  return (
    <Box>
      <Typography>Welcome to Virtual Escape Room!</Typography>
      <Typography>
        Your "{props.eventData.game}" adventure is locked unitl
        {props.eventData.dateAndTime} {props.eventData.timezone}
      </Typography>
    </Box>
  );
};

export default LockedInfo;
