import React, { useState, useEffect, useRef, Fragment } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { Form, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import "./Styles.css";
import { retrieveTeamInfo } from "../../_util/api";
import Timer from "../../_components/Timer";
import { Outlet } from "react-router-dom";
import useSound from "use-sound";
import CSSTransition from "react-transition-group/CSSTransition"; // use this to have built-in control of which classes to assign at the various transition states (entering, entered, exiting, exited)
import { usePusher } from "../../_context/PusherContext";
import { motion, AnimatePresence, useAnimationControls } from "framer-motion";
import InstructionText from "../components/InstructionText";
import hintModalRevealSound from "../media/hintModalRevealSound.mp3";

const Sleuther2 = () => {
  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  const navigate = useNavigate();

  const loaderData = useLoaderData();
  const actionData = useActionData();

  const myAppRef = document.getElementById("nefarious-villain-console"); // this is for the typewriter component

  const [playHint] = useSound(hintModalRevealSound);

  const [timerTime, setTimerTime] = useState(loaderData.Accumulated_Seconds);
  const [revealHint, setRevealHint] = useState(false);
  const [hintInstructions, setHintInstructions] = useState();
  const [hintFlicker, setHintFlicker] = useState(false);
  const [hint1Display, setHint1Display] = useState("");
  const [hint2Display, setHint2Display] = useState("");
  const [hint3Display, setHint3Display] = useState("");

  const [partOneSolved, setPartOneSolved] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const clickHandler = () => {
    setRevealHint(!revealHint);
    playHint();
  };

  useEffect(() => {
    let hint_instructions = "";
    if (loaderData.Hint_ID == "0") {
      hint_instructions =
        "HINTS USED: 0/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your first hint will add 2 MINUTES to your score.";
    } else if (loaderData.Hint_ID == "1") {
      hint_instructions =
        "HINTS USED: 1/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your second hint will add 3 MINUTES to your score.";
    } else if (loaderData.Hint_ID == "2") {
      hint_instructions =
        "HINTS USED: 2/3 Only the Team Leader may buy a hint, but if you think that your team would benefit by having one, you should let them know! Your third and final hint will add 5 MINUTES to your score.";
    } else {
      hint_instructions =
        "HINTS USED: 3/3 There are no more hints available to buy. Read through the ones you've been given and you'll be able to solve this challenge.";
    }
    setHintInstructions(hint_instructions);
  }, [loaderData]);

  useEffect(() => {
    setIsInitializing(false);

    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      console.log(
        data["event_id"] +
          ":" +
          loaderData.Event_ID +
          ",   " +
          data["team_id"] +
          ":" +
          loaderData.Team_ID
      );
      if (
        data["event_id"] === loaderData.Event_ID.toString() &&
        data["team_id"] == loaderData.Team_ID.toString()
      ) {
        if (data["push_type"] === "sleuther_reroute") {
          // This occurs when this team's Team Leader submits a correct response
          console.log("Pusher sessionID = " + pusher["sessionID"].toString());
          navigate(data["url"]);
        }
      }
    });
    // PUSHER /////////
  }, []);

  return (
    <Fragment>
      <Container fluid={true} style={{ backgroundColor: "#333232" }}>
        <Row>
          <Col md="8" className="ver-top-row text-white">
            <motion.div
              initial={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.1, duration: 0.5 }}
            >
              <InstructionText typeText="We'll begin with some good OLD FASHIONED riddles. The observant among you may notice my clever capitalization in the previous sentence - and it is indeed significant, as an alligator is to a crocodile. Confused? Good. Remember - you may use internet search engines or any other means at your disposal to aid in your libationist liberation quest, but use them wisely. You don't want to be falling down any rabbit holes. Since this is the first challenge in our little game, I'll go easy on you: there is no time penalty for guessing incorrectly. Better start solving..." />
            </motion.div>
          </Col>
          <Col
            md="4"
            className="ver-top-row text-center text-white background-gray"
          >
            <Row>
              <Col>
                <h1
                  className="p-1"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 15,
                    fontSize: 60,
                    color: "#0bdf08",
                  }}
                >
                  {/* <Timer accumulatedSeconds={timerTime} /> */}
                </h1>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                className="text-center"
                style={{
                  borderRadius: 15,
                  boxShadow: "5px 5px black",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <h3 className="mb-0">Team ID:</h3>
                <motion.h1
                  whileHover={{
                    scale: 1.1,
                    textShadow: "0px 0px 8px rgb(255,255,255)",
                  }}
                  className="mt-0"
                >
                  {loaderData.Team_ID}
                </motion.h1>
              </Col>
              <Col>
                <button
                  className="instruction-text__button"
                  onClick={clickHandler}
                >
                  Hint
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center text-center text-white">
          <CSSTransition
            in={isInitializing}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <div className="text-center">
              <h1>Initializing...</h1>
            </div>
          </CSSTransition>
          <CSSTransition
            in={!partOneSolved && !isInitializing}
            timeout={1000}
            mountOnEnter
            unmountOnExit
            classNames={{
              enter: "",
              enterActive: "phaseshift-enter-active",
              exit: "",
              exitActive: "phaseshift-exit-active",
              appear: "",
              appearActive: "",
            }}
          >
            <Col md="8" className={`ver-bottom-row`}>
              <Row className="my-4">
                <h1>What Am I?</h1>
                <Col md="6">
                  <h3 className="p-2">
                    I can be rocky inside and out, or possibly frozen to the
                    core.
                  </h3>
                </Col>
                <Col md="6">
                  <h3 className="p-2">
                    Hold your horses and spare me your gold, silver, and bronze;
                    only one metal will do.
                  </h3>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md="6">
                  <h3 className="p-2">
                    A vampire feasting in a kilt on a beach.
                  </h3>
                </Col>
                <Col md="6">
                  <h3 className="p-2">
                    Ask not "what's inside" but "what isn't" and you may have a
                    better chance of identifying me.
                  </h3>
                </Col>
              </Row>
            </Col>
          </CSSTransition>
          <Col md="4" className="ver-bottom-row scroll-x overflow-y-scroll">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Offcanvas
        backdrop={false}
        direction="end"
        fade={false}
        scrollable
        isOpen={revealHint}
      >
        <OffcanvasHeader onClick={clickHandler}>
          <Container>
            <Row>
              <Col>
                <h3 className="instruction-text__button no-btn">Close</h3>
              </Col>
              <Col>
                <h1 id="hint-timer">
                  <Timer accumulatedSeconds={timerTime} />
                </h1>
              </Col>
            </Row>
          </Container>
        </OffcanvasHeader>
        <OffcanvasBody>
          <strong>{hintInstructions}</strong>
          <h6>{hint1Display}</h6>
          <h6>{hint2Display}</h6>
          <h6>{hint3Display}</h6>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Sleuther2;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}
