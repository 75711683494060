import React from "react";
import { Form } from "react-router-dom";

const FormCreateTeamData = () => {
  return (
    <Form className="appear-4" method="post">
      <input
        type="text"
        name="teamName"
        id="teamName"
        className="team-info-form__input text-center"
      />
      <button className="instruction-text__button">Enter</button>
    </Form>
  );
};

export default FormCreateTeamData;
