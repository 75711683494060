import { getCookie } from "../../_util/api";
import { json, redirect } from "react-router-dom";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function CreatePlayerDataMissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    playerName: formData.get("playerName"),
  };
  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/CreatePlayerData/`,
    context
  );

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "Could not create PlayerData", status: 500 });
  }

  const playerData = await response.json();

  return redirect(
    "/mission-escape/" + params.eventId + "/begin/" + playerData.playerId + "/2"
  );
}
