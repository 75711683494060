import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Form, useOutletContext } from "react-router-dom";

const Begin3MissionEscape = () => {
  const [role, setRole] = useState("");

  const handleRoleSelection = (e) => {
    setRole(e.target.value);
  };

  return (
    <>
      <Box>
        <Typography>Choose Your Role:</Typography>
        <Form method="post">
          <Button onClick={handleRoleSelection} value="Squad Leader">
            Squad Leader
          </Button>
          <Button onClick={handleRoleSelection} value="Field Operative">
            Field Operative
          </Button>
          <Button onClick={handleRoleSelection} value="Tech Tactician">
            Tech Tactician
          </Button>
          <TextField
            value={role}
            name="playerRole"
            id="playerRole"
            sx={{ display: "none" }}
          />
          {role !== "" && <Button type="submit">Submit</Button>}
        </Form>
      </Box>
    </>
  );
};

export default Begin3MissionEscape;
