import React from "react";

const About = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
        }}
      >
        <svg style={{ margin: "auto" }} width="280" height="280">
          <rect x="10" y="10" width="270" height="270" />
        </svg>
      </div>
    </>
  );
};

export default About;
