import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Image from "react-bootstrap/Image";
import TypeWriterEffect from "react-typewriter-effect";
import "./Styles.css";
import { retrieveEventInfo } from "../../_util/api";
import CommandPrompt from "../components/CommandPrompt";

const Begin1 = (args) => {
  const eventInfo = useLoaderData();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div"); // this is needed for the typing effect
  if (eventInfo.Unlocked) {
    return (
      <div>
        <Modal
          isOpen={modal}
          size={"lg"}
          className="console-zoom-in bg-window rounded-3"
        >
          <CommandPrompt />
          <ModalBody className="bg-console modal-height">
            <div className="instruction-text__item">
              C:\Users\nefarious\villain
            </div>
            <div className="instruction-text__item">
              <TypeWriterEffect
                startDelay={1000}
                cursorColor="white"
                text="I have hacked into your video call and banished your Mixologist to a hidden breakout room, locked under a passcode and a password. Want them back? Then you'll have to play my little game. Please ensure you are using *GOOGLE CHROME* as your internet browser throughout this game. Otherwise, my game won't work well and your mixologist will be trapped FOREVER! If you are not currently using Google Chrome, please copy and paste the URL from your current browser into Google Chrome right now BEFORE you press continue."
                typeSpeed={50}
                scrollArea={myAppRef}
                textStyle={{
                  fontWeight: 500,
                  fontSize: "1.9em",
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter className="bg-console text-center">
            <div className="my-1 appear-33">
              <Link
                className="instruction-text__button"
                to={`/escapin-not-stirred/begin2/${eventInfo.Event_ID}`}
              >
                Continue
              </Link>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="text-center text-white">
        <h1>Your Event is Locked</h1>
      </div>
    );
  }
};

export default Begin1;

export function loader({ params }) {
  return retrieveEventInfo(params.event_id);
}
