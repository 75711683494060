import { useState, Fragment } from "react";
import { Modal, ModalBody, Container, Row, Col } from "reactstrap";
import Image from "react-bootstrap/Image";
import TypeWriterEffect from "react-typewriter-effect";
import { Form, useParams, redirect } from "react-router-dom";
import { createTeamIDVerification } from "../../_util/api";
import "./Styles.css";
import CommandPrompt from "../components/CommandPrompt";

const Sleuther1 = () => {
  const params = useParams();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div");
  return (
    <Fragment>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="Please wait until the Team Leader has shared their screen AND started the game. The game starts when the Team Leader presses a giant green 'Start Game' button. When the game has started, the Team Leader will be displaying the Game Board on their screenshare. When this happens, check the top right corner of the Game Board and enter the 4-character Team ID that you see there into the input box below. Until then, sit tight!"
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
        </ModalBody>
        <ModalBody className="bg-console text-center">
          <Form
            method="post"
            action={`/escapin-not-stirred/sleuther1/${params.event_id}`}
          >
            <input
              type="text"
              name="Team_ID"
              id="team_id"
              className="team-info-form__input text-center"
            />
            <button className="instruction-text__button">Enter</button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Sleuther1;

export async function action({ request, params }) {
  const formData = await request.formData();
  const post = {
    Event_ID: params.event_id,
    Team_ID: formData.get("Team_ID"),
  };
  const team_verification_results = await createTeamIDVerification(post);
  if (team_verification_results["Outcome"] === "Valid Team ID") {
    return redirect(
      "/escapin-not-stirred/sleuther2/" +
        post.Event_ID +
        "/" +
        post.Team_ID +
        "/gameboard"
    );
  } else if (team_verification_results["Outcome"] === "Invalid Team ID") {
    alert("Invalid Team ID, please try again.");
  }
}
