const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function RetrieveTeamData({ params }) {
  const response = await fetch(
    `${API_ENDPOINT}/api/RetrieveTeamData/?eventId=${params.eventId}&teamId=${params.teamId}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch TeamData data for team_id = ${params.teamId}`,
      status: 500,
    };
  }
  return response.json();
}
