import React from "react";
import List from "@mui/material/List";
import ReviewItem from "./ReviewItem";

const ReviewList = (props) => {
  return (
    <List>
      {props.reviews.map((review) => (
        <ReviewItem review={review} />
      ))}
    </List>
  );
};

export default ReviewList;
