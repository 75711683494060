import { ThemeProvider, createTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "Lato",
  },
  palette: {
    gray: {
      light: "#a3a3a3",
      main: "#575757",
      dark: "#383838",
    },
    red: {
      light: "#f59b92",
      main: "#ec2f0d",
      dark: "rgb(171, 11, 11)",
    },
  },
});

function ThemeMissionEscape() {
  return (
    <ThemeProvider theme={theme}>
      <Outlet />
    </ThemeProvider>
  );
}

export default ThemeMissionEscape;
