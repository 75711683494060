import { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import TypeWriterEffect from "react-typewriter-effect";
import { Link, useOutletContext, useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import CommandPrompt from "../components/CommandPrompt";
import zoomShareAudio from "../media/zoomShareAudio.png";
import zoomShareScreenButton from "../media/zoomShareScreenButton.png";
import zoomShareScreenExample from "../media/zoomShareScreenExample.png";

const Leader3MissionEscape = () => {
  const [eventData, playerData] = useOutletContext();
  const params = useParams();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div");

  return (
    <div>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="Please share your computer's screen, *INCLUDING AUDIO*. You can do this by pressing the Share Screen button in the middle of the meeting's toolbar. To share audio, MAKE SURE THAT the 'Share Sound' checkbox is checked prior to clicking the second 'Share Screen' button - you will all need to be able to hear me throughout the game. Detailed instructions for screen/audio sharing are here (scroll down if you can't see them). Read Steps 1-3 carefully and follow them exactly in order."
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
          <ol class="px-5 text-white appear-20">
            <li class="mt-4">
              Click the Share Screen button located in your Zoom meeting
              controls (move your mouse or touchpad for the meeting controls to
              appear)
            </li>
            <div className="text-center">
              <Image fluid={true} src={zoomShareScreenButton} />
            </div>
            <li class="mt-4">
              <strong class="display-1">
                <i class="fas fa-exclamation-triangle txt-error-ver"></i>
              </strong>{" "}
              Select "Screen" and check the "Share sound" box in the lower left
              corner of the popup
              <Image fluid={true} src={zoomShareAudio} />
            </li>
            <li class="mt-4">
              Click the blue "Share" button
              <Image fluid={true} src={zoomShareScreenExample} />
            </li>
          </ol>
        </ModalBody>
        <ModalFooter className="bg-console text-center">
          <Link
            className="instruction-text__button"
            to={`/mission-escape/${params.eventId}/${params.playerId}/leader/${params.teamId}/4`}
          >
            Continue
          </Link>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Leader3MissionEscape;
