import React from "react";
import { Form, redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";

const FormUpdateTeamData1 = () => {
  return (
    <Form className="appear-3" method="post">
      <input
        type="text"
        name="teamMembers"
        id="teamMembers"
        className="team-info-form__input text-center"
      />
      <button className="instruction-text__button">Enter</button>
    </Form>
  );
};

export default FormUpdateTeamData1;

export async function action({ request, params }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    teamId: params.teamId,
    teamMembers: formData.get("teamMembers"),
  };

  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/UpdateTeamData1/?eventId=${params.eventId}&teamId=${params.teamId}`,
    context
  );

  if (!response.ok) {
    throw { message: "Could not update Team Data", status: 500 };
  } else {
    return redirect(
      "/escapin-not-stirred/" + post.eventId + "/" + post.teamId + "/leader/3"
    );
  }
}
