import { useState } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { Link, useLoaderData } from "react-router-dom";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Image from "react-bootstrap/Image";
import CommandPrompt from "../components/CommandPrompt";

import "./Styles.css";

import { retrieveEventInfo } from "../../_util/api";

const Begin2 = () => {
  const eventInfo = useLoaderData();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div"); // this is needed for the typing effect
  return (
    <div>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="You have also been transported and locked into breakout rooms in small groups. The other people in your breakout room are your teammates. If you don't know them, then introduce yourself. You're going to need them to escape..."
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter className="bg-console text-center">
          <div className="my-1 appear-12">
            <Link
              className="instruction-text__button"
              to={`/escapin-not-stirred/begin3/${eventInfo.Event_ID}`}
            >
              Continue
            </Link>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Begin2;

export function loader({ params }) {
  return retrieveEventInfo(params.event_id);
}
