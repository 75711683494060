import * as React from "react";
import CardGameTalktales from "../components/CardGameTalktales";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

import desertedIslandEscapeIcon from "../components/desertedIslandEscapeIcon.png";

const Talktales = () => {
  return (
    <Container>
      <h1>
        Thanks for scheduling your event! At this time, your request has been
        forwarded to our TalkTales partners, and they will be reaching out to
        you via email shortly to collect your order details.
      </h1>
    </Container>
  );
};

export default Talktales;
