import { redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function CreateTeamDataMissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    teamName: formData.get("teamName"),
  };
  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/CreateTeamData/`, context);

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw { message: "Could not create Team Info", status: 500 };
  }

  const teamData = await response.json();

  return redirect(
    "/mission-escape/" +
      params.eventId +
      "/" +
      params.playerId +
      "/leader/" +
      teamData.teamId +
      "/2"
  );
}
