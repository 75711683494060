import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Schedule = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box display="flex" m="auto">
            <Typography variant="h1" m="auto">
              2 Options:
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{}}>
          $30/player
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{}}>
          $15/player
        </Grid>
      </Grid>
    </>
  );
};

export default Schedule;
