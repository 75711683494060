import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Image from "react-bootstrap/Image";
import TypeWriterEffect from "react-typewriter-effect";
import "./Styles.css";
import { retrieveEventInfo } from "../../_util/api";
import CommandPrompt from "../components/CommandPrompt";

const Begin3 = () => {
  const eventInfo = useLoaderData();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div"); // this is needed for the typing effect
  return (
    <div>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="From this point forward, read everything CAREFULLY and COMPLETELY if you hope to succeed (you'll probably need to scroll down to see the whole message). Work with your teammates to choose exactly ONE person to be Team Leader. The Team Leader will be responsible for sharing their screen with their teammates via the virtual meeting interface, and should be the most computer savvy among you (don't worry - it isn't rocket science). Team Leader doesn't need to share their screen yet - I'll tell them how to do this with specific instructions in a moment. If you are NOT Team Leader, don't worry - you'll still have access to my game. Are you the Team Leader?"
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.75em",
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter className="bg-console text-center">
          <div className="my-1 appear-33">
            <Link
              className="instruction-text__button mx-1 px-5"
              to={`/escapin-not-stirred/leader1/${eventInfo.Event_ID}`}
            >
              Yes
            </Link>
            <Link
              className="instruction-text__button no-btn mx-1 px-5"
              to={`/escapin-not-stirred/sleuther1/${eventInfo.Event_ID}`}
            >
              No
            </Link>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Begin3;

export function loader({ params }) {
  return retrieveEventInfo(params.event_id);
}
