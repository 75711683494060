import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import Button from "@mui/material/Button";
import Player from "../../_components/Player";
import FormCreateEvent from "./FormCreateEvent";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Filter1TwoToneIcon from "@mui/icons-material/Filter1TwoTone";
import Filter2TwoToneIcon from "@mui/icons-material/Filter2TwoTone";
import ShareModule from "../../_components/ShareModule";
import ReviewList from "./ReviewList.js";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(20deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardGame = (props) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleReviewsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const handlePricingClick = (event) => {
    setAnchorEl2(event.currentTarget);
    setOpen2((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card raised>
        <CardHeader
          avatar={
            <Avatar src={props.badge} sx={{ width: 100, height: 100 }}></Avatar>
          }
          action={
            <IconButton>
              <ShareModule
                url="www.virtualescaperoom.com"
                emailSubject={props.emailSubject}
                sms
                // pinterest
                // instagram
                // linkedIn
                // facebook
                // twitter
              />
            </IconButton>
          }
          title={props.title}
          titleTypographyProps={props.titleTypographyProps}
          subheader={props.subheader}
        />
        {props.video && (
          <Player playing={false} controls={true} url={props.playerUrl} />
        )}
        {!props.video && <img width="100%" src={props.image} />}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="testimonials" onClick={handleReviewsClick}>
            <VerifiedTwoToneIcon
              fontSize="large"
              sx={{ color: "primary.main" }}
            />
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={450}>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "gray.dark",
                      color: "#ffffff",
                      borderRadius: "15px",
                    }}
                  >
                    <ReviewList reviews={props.reviews} />
                  </Box>
                </Fade>
              )}
            </Popper>
          </IconButton>
          <IconButton aria-label="settings" onClick={handlePricingClick}>
            <MonetizationOnTwoToneIcon fontSize="large" color="success" />
            <Popper id={id} open={open2} anchorEl={anchorEl2} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={450}>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "gray.dark",
                      color: "#ffffff",
                      borderRadius: "15px",
                    }}
                  >
                    <List>
                      <ListItem>
                        <Typography variant="h5">2 Options:</Typography>
                      </ListItem>
                      <ListItem>
                        <Filter1TwoToneIcon />
                        <Typography variant="h6" marginLeft={1}>
                          VER-Hosted
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography marginLeft={2}>
                          {" "}
                          $30/player
                          <br />5 player minimum
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Filter2TwoToneIcon />
                        <Typography variant="h6" marginLeft={1}>
                          Self-Hosted
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography marginLeft={2}>
                          $15/player
                          <br />2 player minimum
                        </Typography>
                      </ListItem>
                      <ListItem>
                        {/* <Button variant="contained" color="primary">
                          More Info
                        </Button> */}
                      </ListItem>
                    </List>
                  </Box>
                </Fade>
              )}
            </Popper>
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <Button>Schedule Your Event</Button>
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <FormCreateEvent game={props.game} />
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default CardGame;
