import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useAnimationControls } from "framer-motion";
import { Container, Grid, Box, Paper, Typography } from "@mui/material";
import Player from "../../_components/Player";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import EventAvailableTwoToneIcon from "@mui/icons-material/EventAvailableTwoTone";
import BubbleChartTwoToneIcon from "@mui/icons-material/BubbleChartTwoTone";

const Home = () => {
  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box p={1}>
                  <Player
                    playing={true}
                    controls={true}
                    url="https://youtu.be/X7938J621lI"
                    // url="https://drive.google.com/file/d/1DiAPlfwkZJvTdRKm3m1KKiS29XkcM1Tl/preview"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box p={2}>
                      <Paper
                        elevation={1}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <BubbleChartTwoToneIcon fontSize="large" />
                        <BubbleChartTwoToneIcon fontSize="large" />
                        <BubbleChartTwoToneIcon fontSize="large" />
                        <Typography variant="h4">500+</Typography>
                        <Typography variant="h6">Organizations</Typography>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box p={2}>
                      <Paper
                        elevation={1}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <EventAvailableTwoToneIcon fontSize="large" />
                        <EventAvailableTwoToneIcon fontSize="large" />
                        <EventAvailableTwoToneIcon fontSize="large" />
                        <Typography variant="h4">1,000+</Typography>
                        <Typography variant="h6">Events</Typography>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box p={2}>
                      <Paper
                        elevation={1}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <PeopleAltTwoToneIcon fontSize="large" />
                        <PeopleAltTwoToneIcon fontSize="large" />
                        <PeopleAltTwoToneIcon fontSize="large" />
                        <Typography variant="h4">50,000+</Typography>
                        <Typography variant="h6"> Participants</Typography>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box p={1}>
                      <Paper elevation={1} sx={{ p: "10px" }}>
                        <Typography variant="p">
                          <strong>Entertaining, Period:</strong> VER vanquishes
                          video gathering fatigue and we've mastered the
                          intersection of fun and teamwork.
                        </Typography>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box p={2}>
                  <Paper
                    elevation={24}
                    sx={{
                      backgroundColor: "primary.light",
                      color: "white.main",
                    }}
                  >
                    <Typography p={1} sx={{ textShadow: "#000000 1px 0 10px" }}>
                      Virtual Escape Room is a tech-enabled, easily accessible
                      game with a simple yet effective user interface. It
                      elegantly showcases the proven collaborative effect of
                      “escapology” – solving fun but mystifying puzzles as a
                      small team by working together creatively.
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box p={1}>
                  <Paper elevation={1} sx={{ p: "10px" }}>
                    <Typography variant="p">
                      <strong>Massively Scalable:</strong> Events can easily
                      accomodate 2 - 1,000+ players simultaneously. Large events
                      welcome!
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                justifyContent="center"
              >
                <Box p={2}>
                  <Paper
                    elevation={24}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white.main",
                    }}
                  >
                    <Typography p={1} sx={{ textShadow: "#000000 1px 0 10px" }}>
                      Teams strategize to decide when to use hints and submit
                      responses. An in-game leaderboard keeps the competitive
                      atmosphere front-of-mind, and a more detailed leaderboard
                      at the end memorializes this wildly entertaining event for
                      postgame banter!
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box p={1}>
                  <Paper elevation={1} sx={{ p: "10px" }}>
                    <Typography variant="p">
                      <strong>Completely Flexible:</strong> Unsure how many
                      participants will show up? No problem. You'll only pay for
                      people who play.
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box p={2}>
                  <Paper
                    elevation={24}
                    sx={{
                      backgroundColor: "primary.dark",
                      color: "white.main",
                    }}
                  >
                    <Typography p={1} sx={{ textShadow: "#000000 1px 0 10px" }}>
                      Your group will still be raving about their experience a
                      week after it takes place, and setting up your event could
                      not be easier. Perfect for office socials, holiday
                      parties, friendly hangouts, and remote gatherings of all
                      kinds.
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
