import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";

import Pusher from "pusher-js";
import { PusherProvider } from "./_context/PusherContext";

//global
import { RetrieveEventData } from "./_loaders/RetrieveEventData";
import { RetrieveTeamData } from "./_loaders/RetrieveTeamData";
import { RetrievePlayerData } from "./_loaders/RetrievePlayerData";
import { RetrieveAttemptData } from "./_loaders/RetrieveAttemptData";
import { RetrieveLeaderboard } from "./_loaders/RetrieveLeaderboard";

//virtualEscapeRoom
import Layout from "./virtualEscapeRoom/pages/Layout";
import LayoutTalktales from "./virtualEscapeRoom/pages/LayoutTalktales";
import Error from "./virtualEscapeRoom/pages/Error";
import ErrorTalktales from "./virtualEscapeRoom/pages/ErrorTalktales";
import Home from "./virtualEscapeRoom/pages/Home";
import About from "./virtualEscapeRoom/pages/About";
import Games from "./virtualEscapeRoom/pages/Games";
import Event from "./virtualEscapeRoom/pages/Event";
import Pricing from "./virtualEscapeRoom/pages/Pricing";
import Saorsa from "./virtualEscapeRoom/pages/Saorsa";
import Talktales from "./virtualEscapeRoom/pages/Talktales";
import TalktalesAdmin from "./virtualEscapeRoom/pages/TalktalesAdmin";
import { action as CreateEventData } from "./virtualEscapeRoom/components/FormCreateEvent";
import { action as CreateEventDataTalktales } from "./virtualEscapeRoom/components/FormCreateEventTalktales";
import { action as UpdateEventData1 } from "./virtualEscapeRoom/components/FormUpdateEventData1";

// //escapinNotStirred
// import NavError from "./escapinNotStirred/components/NavError";
// import GameboardSleuther from "./escapinNotStirred/pages/SleutherGameboard";
// import Begin1 from "./escapinNotStirred/pages/Begin1";
// import Leaderboard from "./_components/Leaderboard";
// import Begin2 from "./escapinNotStirred/pages/Begin2";
// import Begin3 from "./escapinNotStirred/pages/Begin3";
// import EventData from "./escapinNotStirred/components/EventData";
// import TeamData from "./escapinNotStirred/components/TeamData";
// import ThemeEscapinNotStirred from "./escapinNotStirred/components/Theme";
// import Sleuther1 from "./escapinNotStirred/pages/Sleuther1";
// import Leader1 from "./escapinNotStirred/pages/Leader1";
// import { action as CreateTeamData } from "./escapinNotStirred/components/FormCreateTeamData";
// import Leader2 from "./escapinNotStirred/pages/Leader2";
// import { action as UpdateTeamData1 } from "./escapinNotStirred/components/FormUpdateTeamData1";
// import Leader3 from "./escapinNotStirred/pages/Leader3";
// import Leader4 from "./escapinNotStirred/pages/Leader4";
// import { action as UpdateTeamData2 } from "./escapinNotStirred/components/FormUpdateTeamData2";
// import Leader5, {
//   action as EscapinNotStirredChallenge1,
// } from "./escapinNotStirred/pages/Leader5";
// import Leader6, {
//   action as EscapinNotStirredChallenge2,
// } from "./escapinNotStirred/pages/Leader6";
// import Leader7, {
//   action as EscapinNotStirredChallenge3,
// } from "./escapinNotStirred/pages/Leader7";

// //missionEscape
import { CreatePlayerDataMissionEscape } from "./missionEscape/actions/CreatePlayerDataMissionEscape";
import { CreateTeamDataMissionEscape } from "./missionEscape/actions/CreateTeamDataMissionEscape";
import { UpdatePlayerData1MissionEscape } from "./missionEscape/actions/UpdatePlayerData1MissionEscape";
import { UpdatePlayerData2MissionEscape } from "./missionEscape/actions/UpdatePlayerData2MissionEscape";
import { UpdateTeamData1MissionEscape } from "./missionEscape/actions/UpdateTeamData1MissionEscape";
import { UpdateTeamData2MissionEscape } from "./missionEscape/actions/UpdateTeamData2MissionEscape";
import { CreateTeamIdValidationMissionEscape } from "./missionEscape/actions/CreateTeamIdValidationMissionEscape";
import { CreateAttemptData1MissionEscape } from "./missionEscape/actions/CreateAttemptData1MissionEscape";
import { CreateAttemptData2MissionEscape } from "./missionEscape/actions/CreateAttemptData2MissionEscape";
import { CreateAttemptData3MissionEscape } from "./missionEscape/actions/CreateAttemptData3MissionEscape";
import { CreateAttemptData4MissionEscape } from "./missionEscape/actions/CreateAttemptData4MissionEscape";
import { CreateFeedbackMissionEscape } from "./missionEscape/actions/CreateFeedbackMissionEscape";
import ThemeMissionEscape from "./missionEscape/components/Theme";
import EventData from "./missionEscape/components/EventData";
import PlayerData from "./missionEscape/components/PlayerData";
import TeamData from "./missionEscape/components/TeamData";
import Begin1MissionEscape from "./missionEscape/pages/Begin1MissionEscape";
import Begin2MissionEscape from "./missionEscape/pages/Begin2MissionEscape";
import Begin3MissionEscape from "./missionEscape/pages/Begin3MissionEscape";
import Leader1MissionEscape from "./missionEscape/pages/Leader1MissionEscape";
import Leader2MissionEscape from "./missionEscape/pages/Leader2MissionEscape";
import Leader3MissionEscape from "./missionEscape/pages/Leader3MissionEscape";
import Leader4MissionEscape from "./missionEscape/pages/Leader4MissionEscape";
import SleutherMissionEscape from "./missionEscape/pages/SleutherMissionEscape";
import Challenge1MissionEscape from "./missionEscape/pages/Challenge1MissionEscape";
import Challenge2MissionEscape from "./missionEscape/pages/Challenge2MissionEscape";
import Challenge3MissionEscape from "./missionEscape/pages/Challenge3MissionEscape";
import Challenge4MissionEscape from "./missionEscape/pages/Challenge4MissionEscape";
import LeaderboardMissionEscape from "./missionEscape/pages/LeaderboardMissionEscape";
import DetailedLeaderboardMissionEscape from "./missionEscape/pages/DetailedLeaderboardMissionEscape";
import VictoryMissionEscape from "./missionEscape/pages/VictoryMissionEscape";

import Begin1, {
  loader as eventInfoLoader,
} from "./escapinNotStirred/pages/Begin1";
import Begin2 from "./escapinNotStirred/pages/Begin2";
import Begin3 from "./escapinNotStirred/pages/Begin3";
import Leader1, {
  action as createTeamInfo,
} from "./escapinNotStirred/pages/Leader1";
import Sleuther1, {
  action as verifyTeamID,
} from "./escapinNotStirred/pages/Sleuther1";
import Sleuther2, {
  loader as sleutherLoader2,
} from "./escapinNotStirred/pages/Sleuther2";
import Sleuther3, {
  loader as sleutherLoader3,
} from "./escapinNotStirred/pages/Sleuther3";
import Sleuther4, {
  loader as sleutherLoader4,
} from "./escapinNotStirred/pages/Sleuther4";
import Leader2, {
  loader as teamInfoLoader,
  action as updateTeamInfo,
} from "./escapinNotStirred/pages/Leader2";
import Leader3, { loader as loader3 } from "./escapinNotStirred/pages/Leader3";
import Leader4, {
  loader as loader4,
  action as startTimer,
} from "./escapinNotStirred/pages/Leader4";
import Leader5, {
  loader as loader5,
  action as createTeamSolution5,
} from "./escapinNotStirred/pages/Leader5";
import Leader6, {
  loader as loader6,
  action as createTeamSolution6,
} from "./escapinNotStirred/pages/Leader6";
import Leader7, {
  loader as loader7,
  action as createTeamSolution7,
} from "./escapinNotStirred/pages/Leader7";
import Victory1, {
  loader as victoryLoader,
} from "./escapinNotStirred/pages/Victory1";
import Standings, {
  loader as standingsLoader,
} from "./escapinNotStirred/pages/Standings";
import Leaderboard, {
  loader as loader_leaderboard,
} from "./escapinNotStirred/components/Leaderboard";
import DetailedLeaderboard, {
  loader as loader_detailed_leaderboard,
} from "./escapinNotStirred/components/DetailedLeaderboard";

// // Enable pusher logging - don't include this in production
// Pusher.logToConsole = true;

// // Set up pusher instance with main channel subscription
// // Be able to subscribe to the same channel in another component
// // with separate callback but utilizing the existing connection
const pusher = new Pusher("5bdb5802d0999351fa63", {
  cluster: "us2",
  encrypted: true,
});

const router = createBrowserRouter([
  //missionEscape
  {
    path: "/mission-escape",
    element: <ThemeMissionEscape />,
    children: [
      {
        path: ":eventId",
        element: <EventData />,
        loader: RetrieveEventData,
        children: [
          {
            // Receive initial instructions. Enter player name
            path: "begin/1",
            element: <Begin1MissionEscape />,
            action: CreatePlayerDataMissionEscape,
          },
          {
            // Receive initial instructions. Enter player email
            path: "begin/:playerId/2",
            element: <Begin2MissionEscape />,
            action: UpdatePlayerData1MissionEscape,
          },
          {
            // Receive initial instructions. Player selects a role (Squad Leader, Field Operative, Tech Tactician)
            path: "begin/:playerId/3",
            element: <Begin3MissionEscape />,
            action: UpdatePlayerData2MissionEscape,
          },
          {
            path: ":playerId",
            element: <PlayerData />,
            loader: RetrievePlayerData,
            children: [
              {
                // If the Player is Squad Leader, then they fill in team info
                path: "leader",
                children: [
                  {
                    // Create the Team Name
                    path: "1",
                    element: <Leader1MissionEscape />,
                    action: CreateTeamDataMissionEscape,
                  },
                  {
                    // Add in the Team Members
                    path: ":teamId/2",
                    element: <Leader2MissionEscape />,
                    action: UpdateTeamData1MissionEscape,
                  },
                  {
                    // Share screen instructions
                    path: ":teamId/3",
                    element: <Leader3MissionEscape />,
                  },
                  {
                    // Watch/listen to the instructional video (call from the CIA)
                    // Click the "Start Game" button to update the TeamData with starting epoch
                    path: ":teamId/4",
                    element: <Leader4MissionEscape />,
                    action: UpdateTeamData2MissionEscape,
                  },
                ],
              },
              {
                // Else, they sit tight and wait for the Team ID
                path: "sleuther/1",
                element: <SleutherMissionEscape />,
                action: CreateTeamIdValidationMissionEscape,
              },
              {
                // Once all info has been gathered, land here to start the game.
                // Logic will control what renders within the element depending on Player Role
                path: ":teamId",
                element: <TeamData />,
                loader: RetrieveTeamData,
                children: [
                  {
                    path: "1",
                    element: <Challenge1MissionEscape />,
                    action: CreateAttemptData1MissionEscape,
                    children: [
                      {
                        // I am deliberately creating a subroute for the Leaderboard component bc it's going to re-render frequently
                        // and I don't want to have the entire gameboard re-render when it does
                        path: "a",
                        element: <LeaderboardMissionEscape />,
                        loader: RetrieveLeaderboard,
                      },
                    ],
                  },
                  {
                    path: "2",
                    element: <Challenge2MissionEscape />,
                    action: CreateAttemptData2MissionEscape,
                    children: [
                      {
                        path: "a",
                        element: <LeaderboardMissionEscape />,
                        loader: RetrieveLeaderboard,
                      },
                    ],
                  },
                  {
                    path: "3",
                    element: <Challenge3MissionEscape />,
                    action: CreateAttemptData3MissionEscape,
                    children: [
                      {
                        path: "a",
                        element: <LeaderboardMissionEscape />,
                        loader: RetrieveLeaderboard,
                      },
                    ],
                  },
                  {
                    path: "4",
                    element: <Challenge4MissionEscape />,
                    action: CreateAttemptData4MissionEscape,
                    children: [
                      {
                        path: "a",
                        element: <LeaderboardMissionEscape />,
                        loader: RetrieveLeaderboard,
                      },
                    ],
                  },
                  {
                    path: "victory",
                    element: <VictoryMissionEscape />,
                    action: CreateFeedbackMissionEscape,
                    children: [
                      {
                        path: "a",
                        element: <DetailedLeaderboardMissionEscape />,
                        loader: RetrieveLeaderboard,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  //virtualEscapeRoom
  {
    path: "/",
    element: <Layout />,
    // errorElement: <Error />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      {
        path: "games",
        element: <Games />,
        action: CreateEventData,
      },
      {
        path: "event/:eventId",
        element: <Event />,
        loader: RetrieveEventData,
        action: UpdateEventData1,
      },
      { path: "pricing", element: <Pricing /> },
      { path: "saorsa", element: <Saorsa /> },
    ],
  },
  {
    path: "/talktales/",
    element: <LayoutTalktales />,
    action: CreateEventDataTalktales,
  },
  {
    path: "/talktales/:eventId",
    element: <Talktales />,
    errorElement: <ErrorTalktales />,
  },
  { path: "admin/:eventId", element: <TalktalesAdmin /> },

  //escapinNotStirred
  {
    path: "/escapin-not-stirred/begin1/:event_id",
    element: <Begin1 />,
    loader: eventInfoLoader,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/begin2/:event_id",
    element: <Begin2 />,
    loader: eventInfoLoader,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/begin3/:event_id",
    element: <Begin3 />,
    loader: eventInfoLoader,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/leader1/:event_id",
    element: <Leader1 />,
    action: createTeamInfo,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/sleuther1/:event_id",
    element: <Sleuther1 />,
    action: verifyTeamID,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/leader2/:event_id/:team_id",
    element: <Leader2 />,
    loader: teamInfoLoader,
    action: updateTeamInfo,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/sleuther2/:event_id/:team_id",
    element: <Sleuther2 />,
    loader: sleutherLoader2,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
    children: [
      {
        path: "/escapin-not-stirred/sleuther2/:event_id/:team_id/gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        errorElement: <h1>Could not load leaderboard</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/leader3/:event_id/:team_id",
    element: <Leader3 />,
    loader: loader3,
    errorElement: <h1>Team Info not found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/leader4/:event_id/:team_id",
    element: <Leader4 />,
    loader: loader4,
    action: startTimer,
    errorElement: <h1>Team Info not found - please double-check the URL</h1>,
  },
  {
    path: "/escapin-not-stirred/leader5/:event_id/:team_id",
    element: <Leader5 />,
    loader: loader5,
    errorElement: <h1>Gameboard couldn't be loaded</h1>,
    children: [
      {
        path: "gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        action: createTeamSolution5,
        errorElement: <h1>Leaderboard couldn't be loaded</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/leader6/:event_id/:team_id",
    element: <Leader6 />,
    loader: loader6,
    errorElement: <h1>Gameboard couldn't be loaded</h1>,
    children: [
      {
        path: "gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        action: createTeamSolution6,
        errorElement: <h1>Leaderboard couldn't be loaded</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/sleuther3/:event_id/:team_id",
    element: <Sleuther3 />,
    loader: sleutherLoader3,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
    children: [
      {
        path: "/escapin-not-stirred/sleuther3/:event_id/:team_id/gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        errorElement: <h1>Could not load leaderboard</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/leader7/:event_id/:team_id",
    element: <Leader7 />,
    loader: loader7,
    errorElement: <h1>Gameboard couldn't be loaded</h1>,
    children: [
      {
        path: "gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        action: createTeamSolution7,
        errorElement: <h1>Leaderboard couldn't be loaded</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/sleuther4/:event_id/:team_id",
    element: <Sleuther4 />,
    loader: sleutherLoader4,
    errorElement: <h1>Event Not Found - please double-check the URL</h1>,
    children: [
      {
        path: "/escapin-not-stirred/sleuther4/:event_id/:team_id/gameboard",
        element: <Leaderboard />,
        loader: loader_leaderboard,
        errorElement: <h1>Could not load leaderboard</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/victory1/:event_id/:team_id",
    element: <Victory1 />,
    loader: victoryLoader,
    children: [
      {
        path: "/escapin-not-stirred/victory1/:event_id/:team_id/gameboard",
        element: <DetailedLeaderboard />,
        loader: loader_detailed_leaderboard,
        errorElement: <h1>Leaderboard couldn't be loaded</h1>,
      },
    ],
  },
  {
    path: "/escapin-not-stirred/:event_id",
    element: <Standings />,
    children: [
      {
        path: "leaderboard",
        element: <DetailedLeaderboard />,
        loader: loader_detailed_leaderboard,
        errorElement: <h1>Leaderboard couldn't be loaded</h1>,
      },
    ],
  },
]);

function App() {
  return (
    <PusherProvider pusher={pusher}>
      <RouterProvider router={router} />
    </PusherProvider>
  );
}

export default App;
