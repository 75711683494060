import React, { useState } from "react";
import {
  Form,
  useActionData,
  useNavigation,
  json,
  redirect,
} from "react-router-dom";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { Button } from "reactstrap";
import { Box } from "@mui/material";
import { getCookie } from "../../_util/api";

const FormCreateEvent = (props) => {
  const data = useActionData();
  let organizerError = false;
  let organizerHelperText = "";
  if (data && data.errors.organizer) {
    organizerError = true;
    organizerHelperText = data.errors.organizer;
  }
  let emailError = false;
  let emailHelperText = "";
  if (data && data.errors.email) {
    emailError = true;
    emailHelperText = data.errors.email;
  }
  let dateAndTimeError = false;
  let dateAndTimeHelperText = "";
  if (data && data.errors.dateAndTime) {
    dateAndTimeError = true;
    dateAndTimeHelperText = data.errors.dateAndTime;
  }
  let timezoneError = false;
  let timezoneHelperText = "";
  if (data && data.errors.timezone) {
    timezoneError = true;
    timezoneHelperText = data.errors.timezone;
  }

  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const [value, setValue] = useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [timezone, setTimezone] = useState("");

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  return (
    <Form method="post" noValidate>
      <FormControl fullWidth>
        <TextField
          name="organizer"
          error={organizerError}
          helperText={organizerHelperText}
          id="yourName"
          label="Your Name"
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <TextField
          name="email"
          error={emailError}
          helperText={emailHelperText}
          id="yourEmail"
          label="Your Email"
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Date & Time"
            minutesStep={15}
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                name="dateAndTime"
                error={dateAndTimeError}
                helperText={dateAndTimeHelperText}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }} error={timezoneError}>
        <InputLabel id="timezone-select-label">Timezone</InputLabel>
        <Select
          name="timezone"
          labelId="timezone-select-label"
          id="timezone-select"
          value={timezone}
          label="Timezone"
          onChange={handleTimezoneChange}
        >
          <MenuItem value={"US/Eastern"}>US/Eastern</MenuItem>
          <MenuItem value={"US/Central"}>US/Central</MenuItem>
          <MenuItem value={"US/Mountain"}>US/Mountain</MenuItem>
          <MenuItem value={"US/Pacific"}>US/Pacific</MenuItem>
          <MenuItem value={"GMT/UTC"}>GMT/UTC</MenuItem>
        </Select>
        <FormHelperText>{timezoneHelperText}</FormHelperText>
      </FormControl>
      <TextField
        value={props.game}
        name="game"
        id="game"
        sx={{ display: "none" }}
      />
      <Box mb={2}></Box>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Continue"}
      </Button>
    </Form>
  );
};

export default FormCreateEvent;

export async function action({ request }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const data = await request.formData();

  const eventData = {
    organizer: data.get("organizer"),
    email: data.get("email"),
    dateAndTime: data.get("dateAndTime"),
    timezone: data.get("timezone"),
    game: data.get("game"),
  };

  const method = "POST";
  const body = JSON.stringify(eventData);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/CreateEventData/`, context);

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "could not create event", status: 500 });
  }

  const resData = await response.json();

  return redirect("/event/" + resData.eventId);
}
