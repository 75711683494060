import React, { useState, useEffect } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";

const FormUpdateEventData2 = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [isUpdatingTimeslot, setIsUpdatingTimeslot] = useState(false);

  async function handleTimeslotChange(event) {
    event.preventDefault();

    setIsUpdatingTimeslot(true);
    console.log(
      "handleTimeslotChange function has fired and the value is: " +
        event.target.value
    );
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const response = await fetch(
      `${API_ENDPOINT}/api/UpdateEventData2/?eventId=` + params.eventId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateAndTime: event.target.value,
        }),
      }
    );
    const data = await response.json();
    const resData = data.results;

    if (response.status === 422 || response.status === 401) {
      return response;
    }

    if (!response.ok) {
      throw json({ message: "could not update event", status: 500 });
    }

    navigate("/event/" + params.eventId); // I am navigating (redirecting) to the same page we were at so that I effectively refresh the data presented
  }

  return (
    <form method="post">
      <Box>
        {!isUpdatingTimeslot && (
          <Box>
            {props.shortNotice && (
              <Typography color={"warning.main"}>
                All VER Hosts are already reserved for the date & time you've
                selected. Not to worry! You can still proceed with a self-hosted
                event (it's easy - and cheaper!!)
              </Typography>
            )}
            {props.conflictingEvents && (
              <Typography color={"warning.main"}>
                All VER Hosts are already reserved for the date & time you've
                selected. Not to worry! You can either continue with a
                Self-Hosted event or choose an available time:
              </Typography>
            )}
          </Box>
        )}
        {isUpdatingTimeslot && <Typography></Typography>}
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            className="hiddenScrollbar"
            sx={{
              height: "100%",
              overflowY: "hidden",
              overflowX: "scroll",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "inline-flex",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              {props.eventNotes.availableTimeslots.map((timeslot) => (
                <Button
                  onClick={handleTimeslotChange}
                  name="eventHourMinute"
                  id="eventHourMinute"
                  sx={{
                    marginLeft: "2px",
                    marginRight: "2px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  value={timeslot}
                >
                  {timeslot}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default FormUpdateEventData2;
