import React, { useState, useRef, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Outlet, useLoaderData } from "react-router-dom";

import "./Styles.css";
import { retrieveTeamInfo } from "../../_util/api";

const Standings = () => {
  const loaderData = useLoaderData();
  const myAppRef = document.querySelector(".scrollable-div");

  return (
    <Fragment>
      <Container>
        <Row>
          <Col className="text-center text-white">
            <h1>Leaderboard Standings</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Standings;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}
