import * as React from "react";
import CardGame from "../components/CardGame";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

import desertedIslandEscapeMarketing from "../components/desertedIslandEscapeMarketing.png";
import goForGoldMarketing from "../components/goForGoldMarketing.png";
import missionEscapeMarketing from "../components/missionEscapeMarketing.png";
import gameShowEscapadeMarketing from "../components/gameShowEscapadeMarketing.png";
import friendFlightFiascoMarketing from "../components/friendFlightFiascoMarketing.png";
import insuranceFraudInvestigationMarketing from "../components/insuranceFraudInvestigationMarketing.png";
import holidayCheersMarketing from "../components/holidayCheersMarketing.png";

import escapinNotStirredIcon from "../components/escapinNotStirredIcon.png";
import missionEscapeIcon from "../components/missionEscapeIcon.png";
import spookyMurderMysteryIcon from "../components/spookyMurderMysteryIcon.png";
import goForGoldIcon from "../components/goForGoldIcon.png";
import desertedIslandEscapeIcon from "../components/desertedIslandEscapeIcon.png";
import gameShowEscapadeIcon from "../components/gameShowEscapadeIcon.png";
import friendFlightFiascoIcon from "../components/friendFlightFiascoIcon.png";
import insuranceFraudInvestigationIcon from "../components/insuranceFraudInvestigationIcon.png";
import holidayCheersIcon from "../components/holidayCheersIcon.png";

const Games = () => {
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ x: -1000, y: -1000 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Mission: Escape"
                badge={missionEscapeIcon}
                emailSubject="Mission:Escape Virtual Escape Room adventure"
                title="MISSION: ESCAPE"
                titleTypographyProps={{ variant: "h4", pt: "21px", pb: "21px" }} // need padding top and bottom here bc title is only 2 words
                subheader="Medium-Hard Difficulty"
                video={false}
                image={missionEscapeMarketing}
                description="You are an elite team of secret agents tasked with saving the world
                from a nefarious villain. You must use your high-tech gadgetry to
                infiltrate their secret lair, extract some actionable intel, and
                escape without tripping any alarms. Do you have what it takes?"
                reviews={[
                  "This VER was so fun and it's wonderful learning how Paul built this during the pandemic, this is a much needed experience :)  Thank you for creating this!",
                  "It was a really good experience. All of the mechanics of the game were good.",
                  "Great event and lots of fun. Collaboration was necessary.",
                  "I've already recommended this to another colleague. It was a blast!",
                  "Was my first escape room ever. Was a lot of fun!",
                  "This was fun and challenging. Thanks!",
                ]}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Deserted Island Escape"
                badge={desertedIslandEscapeIcon}
                emailSubject="Deserted Island Escape Virtual Escape Room adventure"
                title="DESERTED ISLAND ESCAPE"
                titleTypographyProps={{ variant: "h4" }}
                subheader="Hard Difficulty"
                video={false}
                image={desertedIslandEscapeMarketing}
                description="You've been left behind on an abandoned island with no cell phone
                service, no boat, and no way to make a signal fire. Oops! Luckily,
                someone has left you clues to find a viable escape route. Solve a
                series of challenges to reach the extraction point before you miss
                your window to escape and are trapped on the island forever!"
                reviews={[
                  "We had an amazing time, so much fun! Our host was great, such a fun and different way to spend time with the team!",
                  "I'm not usually a huge escape room fan but this was fun and so creative done in a virtual way! awesome job!",
                  "Very good event",
                  "Paul was an amazing host to our holiday event. Our team had a lot of fun working through the challenges.",
                  "The team had so much fun, even when we got stuck trying to count together! We shared lots of laughs and in the end we escaped haha!",
                ]}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ x: 1000, y: -1000 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Spooky Murder Mystery"
                badge={spookyMurderMysteryIcon}
                emailSubject="Spooky Murder Mystery Virtual Escape Room adventure"
                title="SPOOKY MURDER MYSTERY"
                titleTypographyProps={{ variant: "h4" }}
                subheader="Medium-Hard Difficulty"
                video={true}
                playerUrl="https://youtu.be/CYv8qXfDLmM"
                description="A murder has been committed! Whodunnit? It's your job to figure it out
                - you are detectives working as a team to whittle down a list of
                creepy suspects until you determine who perpetrated the terrible
                crime. While spooky, this experience doesn't take itself too seriously
                and is suitable for participants of all ages."
                reviews={[
                  "I really enjoyed how engaging this virtual escape room is! I've only done one escape room in real life, and it was super fun. I thought a lot of those elements are difficult in a virtual environment, but you all did it! I am super impressed with how hard the riddles and games are, but this halloween themed escape room was amazing!",
                  "Tons of fun, very challenging, thanks for recommending us to use the hints. Loved this, perfect for Halloween today!",
                  "Overall a GREAT experience. The UI was well done and self explanatory.",
                  "Fantastic programming, as always!",
                  "Loved the challenge, I had a great time solving with my team, and gained confidence as we progressed through each puzzle. It was easy to navigate, and also awesome as an organizer to participate! Great job!",
                  "Loved the simplicity--still challenging but nice to actually make it out of the room within 40 mins!",
                  "Perfect for Halloween, spooky for sure & plenty of trick 'n' treats, challenging puzzles & our host was helpful & friendly too :)",
                ]}
              />
            </motion.div>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ x: -1000, y: -1000 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Escapin' Not Stirred"
                badge={escapinNotStirredIcon}
                emailSubject="Escapin' Not Stirred Virtual Escape Room adventure"
                title="ESCAPIN' NOT STIRRED"
                titleTypographyProps={{ variant: "h4" }}
                subheader="Medium Difficulty"
                video={true}
                playerUrl="https://youtu.be/qBBDspf6teg"
                description="Your virtual host has been kidnapped! Sip your way to victory and help set them free in this outrageously fun cocktail-themed virtual escape room adventure. You can optionally upgrade your event to include the shipment and delivery of a cocktail kit - we've partnered with TalkTales Entertainment to create a one-of-a-kind fusion virtual event!"
                reviews={[
                  "We had a blast!! The puzzles were engaging, thought-provoking, and challenging. Thank you to Paul and his team for providing quality programming at an affordable price!",
                  "As a student leader in an event-planning role at the University of Virginia's Darden School of Business, I was forced to completely revamp my programming and shift to a mostly virtual environment during the 2020-21 academic year due to COVID-19. I added the Virtual Escape Room to our series of yearlong competitions among students, which provided a fun, challenging, and much-needed distraction and means of social interaction for my classmates. Paul and his team were great to work with and took the time to answer my questions and address our event's unique needs. Since our 'Friend Flight Fiasco' event, I've had multiple students and faculty members reach out to me expressing interest in using the Virtual Escape Room for their own club and family events.",
                  "Fun team building event!",
                  "Lots of fun!",
                  "This was fun! One of the best virtual escape room experiences I've had.",
                  "Great event to leverage team collaboration. Lots of fun while still being challenging!",
                ]}
              />
            </motion.div>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Go For Gold"
                badge={goForGoldIcon}
                emailSubject="Go For Gold Virtual Escape Room adventure"
                title="GO FOR GOLD"
                titleTypographyProps={{ variant: "h4", pt: "21px", pb: "21px" }} // need padding top and bottom here bc title is 3 short words
                subheader="Medium Difficulty"
                video={false}
                image={goForGoldMarketing}
                description="Your multi-talented Olympic team is lost in transit to the 2021 Tokyo Summer Olympic Games. You'd better hurry up and find your way, because once there you'll be competing in Track & Field, Swimming, Basketball, AND Soccer. Work as a team to 'go for the gold' in each challenge to become the most decorated Olympians in history!"
                reviews={[
                  "Got this as a virtual event for my team. Was very interesting and unique as each stage posed a different challenge. Will definitely consider these again for the future.",
                  "Tons of fun, and brainpower. Excellent for a team get-together.",
                  "It was an easy-going, fun way to start the holiday season with our team. Thank you so much!",
                  "Great experience!!!",
                  "We had a great time! I really enjoyed the activity.",
                  "It was really fun and entertaining.",
                ]}
              />
            </motion.div>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ x: 1000, y: -1000 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Game Show Escapade"
                badge={gameShowEscapadeIcon}
                emailSubject="Game Show Escapade Virtual Escape Room adventure"
                title="GAME SHOW ESCAPADE"
                titleTypographyProps={{ variant: "h4" }}
                subheader="Medium-Hard Difficulty"
                video={false}
                image={gameShowEscapadeMarketing}
                description="You are trapped in a major broadcasting studio. A series of rooms that are filming popular game shows stand between you and freedom. Be careful - choosing the wrong path may affect the outcome of your daring escape!"
                reviews={[
                  "This was a brilliant and innovative event that I recommend to any business looking for a morale boost, team building exercise, or just pure fun. They were organized, professional, and a pleasure to work with. Our team loved it!",
                  "Fun, collaborative team-building event! The challenges were hard enough to keep us working hard, but not too difficult that it seemed impossible. Definitely would recommend!",
                  "My sales team just participated... And what a challenge! Even though our brains 'hurt' after the event, we all had fun working together to solve the riddles/puzzles. It was all we could talk about for the first 20 minutes the next day! The event was effortless to set up and added tremendous value for its affordable price!",
                  "A good escape room - challenging without being impossible",
                  "Very challenging but super creative - nice & spooky & a lotta fun :)",
                  "Like so many others, my company has been working remotely since March 2020 at the beginning of the pandemic. We have tried different types of virtual events and this was by far the absolute best virtual employee event we have done so far! We broke into teams and there was some great competition! It was fun to see the way each department worked together to solve the mystery. I received positive feedback from employees and would love to do this again in the future!",
                ]}
              />
            </motion.div>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ x: -1000, y: -1000 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Friend Flight Fiasco"
                badge={friendFlightFiascoIcon}
                emailSubject="Friend Flight Fiasco Virtual Escape Room adventure"
                title="FRIEND FLIGHT FIASCO"
                titleTypographyProps={{ variant: "h4" }}
                subheader="Medium-Hard Difficulty"
                video={false}
                image={friendFlightFiascoMarketing}
                description="Where in the world is Becca? She's your dear friend and isn't responding to your texts or calls. Embark upon a wacky travel-themed adventure and band together to figure out her location and destination using any means at your disposal, including clues given by Becca's active Twitter account."
                reviews={[
                  "Awesome awesome awesome!!!",
                  "This was fun for being virtual. Team had to really work together in several ways to solve the problems and such. Thank you",
                  "Escape rooms are not my thing but I enjoyed this one! Definitely will recommend to friends.",
                  "We have a large team - so many new connections were made...really enjoyed the event.",
                  "Once I get my new email address at my new employer, I'll be sure to connect. The puzzles were challenging, but it was a great team building event and we had fun. We got out so I'll take that as a win.",
                ]}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Insurance Fraud Investigation"
                badge={insuranceFraudInvestigationIcon}
                emailSubject="Insurance Fraud Investigation Virtual Escape Room adventure"
                title="INSURANCE FRAUD INVESTIGATION"
                titleTypographyProps={{ variant: "h5", pt: "15px", pb: "15px" }}
                subheader="Medium-Easy Difficulty"
                video={false}
                image={insuranceFraudInvestigationMarketing}
                description="Did that lightning storm actually cause Leo's house to burn down? That's for your investigative team to figure out - sort your way through a collection of evidence and witness testimonials to determine what or who is truly at fault for this incendiary situation. Perfect for first-time sleuthers and/or shorter events!"
                reviews={[]}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGame
                game="Holiday Cheers!"
                badge={holidayCheersIcon}
                emailSubject="Holiday Cheers! Virtual Escape Room adventure"
                title="HOLIDAY CHEERS!"
                titleTypographyProps={{ variant: "h4", pt: "21px", pb: "21px" }}
                subheader="Medium Difficulty"
                video={false}
                image={holidayCheersMarketing}
                description="We made it to the end of the year, and there are so many festivities to look forward to! Grab your family/friends/coworkers and gather virtually for a series of fun challenges that take you through Thanksgiving, the December holidays (religion agnostic), and New Year's Eve from the comfort of your own couch. Feel free to wear your jammies and bring your own Cup of Cheer (of whatever makes you feel the most cheerful). Family-friendly, perfect for office holiday parties and remote gatherings of all kinds. Cheers!!"
                reviews={[]}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Games;
