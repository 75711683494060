import React, { useState, useRef, Fragment } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Outlet, Form, useLoaderData, redirect } from "react-router-dom";
import { createTeamInfo } from "../../_util/api";
import "./Styles.css";
import { retrieveTeamInfo } from "../../_util/api";
import useSound from "use-sound";
import outroVideo from "../media/outroVideo.mp4";
import phoneIcon from "../media/phoneIcon.png";

const Victory1 = () => {
  const vidRef = useRef(null);

  const loaderData = useLoaderData();
  const myAppRef = document.querySelector(".scrollable-div");

  const [answerButtonVisible, setAnswerButtonVisible] = useState(true);
  const [callVideoVisible, setCallVideoVisible] = useState(false);
  const [offCanvasVisible, setOffCanvasVisible] = useState(true);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setAnswerButtonVisible(false);
    setCallVideoVisible(true);
  };
  const handleGoToLeaderboard = (event) => {
    setOffCanvasVisible(false);
  };

  return (
    <Fragment>
      <Container>
        <Row>
          <Col className="text-center text-white">
            <h5 className="p-2 bg-white text-black rounded-3">
              Interested in playing another Virtual Escape Room adventure? Check
              out our other offerings{" "}
              <a href="https://www.virtualescaperoom.com/games">HERE!</a>
            </h5>
            <h1>Leaderboard Standings</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Offcanvas
        direction="bottom"
        backdrop={false}
        fade={false}
        isOpen={offCanvasVisible}
        className="video-offcanvas bg-console"
      >
        <OffcanvasBody>
          <Container>
            <Row className="align-items-center">
              <Col
                className={`text-center ${answerButtonVisible ? "" : "hidden"}`}
              >
                <h1 className={`txt-white incoming-call-flicker`}>
                  INCOMING CALL...
                </h1>
                <button
                  style={{
                    backgroundImage: `url(${phoneIcon})`,
                  }}
                  className={`mt-5 answer-call-button appear-5`}
                  onClick={handlePlayVideo}
                ></button>
                <h1 className={`text-white appear-5`}>
                  ACCEPT<br></br>CALL
                </h1>
              </Col>
              <Col
                className={`text-center ${answerButtonVisible ? "hidden" : ""}`}
              >
                <video
                  className={`${callVideoVisible ? "" : "hidden"}`}
                  ref={vidRef}
                  controls
                  width="80%"
                  preload="auto"
                  src={outroVideo}
                  type="video/mp4"
                >
                  Sorry, your browser doesn't support embedded videos. Please
                  use Chrome, Firefox, Edge, or Safari.
                </video>
                <br></br>
                <button
                  className={`start-game-button appear-20`}
                  onClick={handleGoToLeaderboard}
                >
                  Game<br></br>Results
                </button>
              </Col>
            </Row>
          </Container>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Victory1;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}
