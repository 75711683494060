import React, { useState, useEffect } from "react";
import { Form, useActionData, json, redirect } from "react-router-dom";
import {
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
  Popper,
  Box,
  Button,
  Fade,
  Slider,
  FormHelperText,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { getCookie } from "../../_util/api";

const sliderValueToSizeRange = {
  0: ["0", 0, 0],
  1: ["1-10", 1, 10],
  2: ["11-20", 11, 20],
  3: ["21-30", 21, 30],
  4: ["31-40", 31, 40],
  5: ["41-50", 41, 50],
  6: ["51-60", 51, 60],
  7: ["61-70", 61, 70],
  8: ["71-80", 71, 80],
  9: ["81-90", 81, 90],
  10: ["91-100", 91, 100],
  11: ["101-150", 101, 150],
  12: ["151-250", 151, 250],
  13: ["251-500", 251, 500],
  14: ["501-1,000", 501, 1000],
  15: ["1,000+", 1000, 1000],
};

const FormUpdateEventData1 = (props) => {
  const data = useActionData();
  let typeError = false;
  if (data && data.errors.type) {
    typeError = true;
  }
  let sizeError = false;
  let sizeHelperText = "";
  if (data && data.errors.size) {
    sizeError = true;
    sizeHelperText = data.errors.size;
  }
  let originError = false;
  let originHelperText = "";
  if (data && data.errors.origin) {
    originError = true;
    originHelperText = data.errors.origin;
  }

  const [originValue, setOriginValue] = useState("");

  const handleOriginChange = (event) => {
    setOriginValue(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const handleVerHostedInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const [open2, setOpen2] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const canBeOpen2 = open2 && Boolean(anchorEl2);
  const id2 = canBeOpen2 ? "transition-popper" : undefined;
  const handleSelfHostedInfoClick = (event) => {
    setAnchorEl2(event.currentTarget);
    setOpen2((previousOpen) => !previousOpen);
  };

  const [hostingType, setHostingType] = useState("");

  const handleHostingTypeChange = (event, newHostingType) => {
    if (newHostingType !== null) {
      setHostingType(newHostingType);
    } else if (newHostingType === null) {
      setHostingType("");
    }
    if (sliderValue !== 0) {
      calcEventEstimate(newHostingType, sliderValue);
    }
  };

  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    if (hostingType !== "") {
      calcEventEstimate(hostingType, newValue);
    }
  };

  function valueLabelFormat(sliderValue) {
    return `${sliderValueToSizeRange[sliderValue][0]}`;
  }

  const [eventEstimate, setEventEstimate] = useState(0);

  const calcEventEstimate = (hostingType, size) => {
    if (hostingType !== "") {
      let price = 0;
      if (hostingType === "VER-Hosted") {
        price = 30;
      } else if (hostingType === "Self-Hosted") {
        price = 15;
      }
      let lowCostEstimate = "$" + price * sliderValueToSizeRange[size][1];
      let highCostEstimate = "$" + price * sliderValueToSizeRange[size][2];
      let divider = " - ";
      if (size === 15) {
        highCostEstimate = "+";
        divider = "";
      }
      setEventEstimate(lowCostEstimate + divider + highCostEstimate);
    }
  };

  const [verHostedDisabled, setVerHostedDisabled] = useState(false);

  useEffect(() => {
    if (props.conflictingEvents) {
      console.log("Yep - there are conflicting events");
      setVerHostedDisabled(true);
      setHostingType("Self-Hosted");
    }
  }, [props.conflictingEvents]);

  return (
    <>
      <Form method="post">
        <Box textAlign={"center"}>
          {hostingType === "" && (
            <Typography
              fontWeight="bold"
              color={typeError ? "error.main" : "black.main"}
            >
              Please Choose An Event Type:
            </Typography>
          )}
          {hostingType !== "" && (
            <Typography>
              <span style={{ fontWeight: "bolder" }}>Event Type:</span>{" "}
              {hostingType}
            </Typography>
          )}
          <ToggleButtonGroup
            color="primary"
            value={hostingType}
            exclusive
            onChange={handleHostingTypeChange}
            sx={{ textAlign: "center" }}
          >
            <IconButton onClick={handleVerHostedInfoClick}>
              <InfoTwoToneIcon fontSize="large" sx={{ color: "gray.main" }} />
              <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={450}>
                    <Box
                      p={1}
                      sx={{
                        width: "100%",
                        maxWidth: 400,
                        bgcolor: "gray.dark",
                        color: "#ffffff",
                      }}
                    >
                      <Typography>
                        VER-Hosted events are a turnkey solution. We send you a
                        virtual meeting link and a comprehensive set of
                        instructions to send to your group. Then we host your
                        event start-to-finish.
                      </Typography>
                    </Box>
                  </Fade>
                )}
              </Popper>
            </IconButton>
            <ToggleButton value="VER-Hosted" disabled={verHostedDisabled}>
              <Typography>
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  VER-Hosted
                </span>
                <br />
                $30/player
              </Typography>
            </ToggleButton>
            <ToggleButton value="Self-Hosted">
              <Typography>
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Self-Hosted
                </span>
                <br />
                $15/player
              </Typography>
            </ToggleButton>
            <IconButton onClick={handleSelfHostedInfoClick}>
              <InfoTwoToneIcon fontSize="large" sx={{ color: "gray.main" }} />
              <Popper id={id2} open={open2} anchorEl={anchorEl2} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={450}>
                    <Box
                      p={1}
                      sx={{
                        width: "100%",
                        maxWidth: 400,
                        bgcolor: "gray.dark",
                        color: "#ffffff",
                      }}
                    >
                      <Typography>
                        Self-hosted events are cheaper, but you'll be
                        responsible for setting up and hosting the video
                        meeting. We'll send you a comprehensive set of hosting
                        instructions, and it takes less than 5 minutes to become
                        completely up to speed. Easy peasy.
                      </Typography>
                    </Box>
                  </Fade>
                )}
              </Popper>
            </IconButton>
          </ToggleButtonGroup>
          <TextField
            value={hostingType}
            name="type"
            id="type"
            sx={{ display: "none" }}
          />
        </Box>
        <Box mt={3} textAlign={"center"}>
          <Typography id="non-linear-slider" gutterBottom>
            <span style={{ fontWeight: "bolder" }}>Estimated Group Size:</span>{" "}
            {valueLabelFormat(sliderValue)}
          </Typography>
        </Box>
        <FormControl fullWidth error={sizeError}>
          <Slider
            value={sliderValue}
            min={0}
            step={1}
            max={15}
            default={0}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
          <FormHelperText>{sizeHelperText}</FormHelperText>
        </FormControl>
        <Box mt={3} textAlign={"center"}>
          {hostingType === "VER-Hosted" && (
            <Typography variant="p">
              Your group will be divided into teams at the start of the event
              (we recommend 4-6 people per team)
            </Typography>
          )}
          {hostingType === "Self-Hosted" && (
            <Typography variant="p">
              You'll divide your group into teams at the start of the event (we
              recommend 4-6 people per team)
            </Typography>
          )}
        </Box>
        <TextField
          value={sliderValue}
          name="size"
          id="size"
          sx={{ display: "none" }}
        />
        <Typography textAlign={"center"} gutterBottom={2}>
          <span style={{ fontWeight: "bold" }}>Cost Estimate:</span>
          <br />
          {hostingType !== "" && sliderValue !== 0 ? (
            <span>{eventEstimate}</span>
          ) : (
            <span>$0</span>
          )}
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }} error={originError}>
          <InputLabel id="origin-select-label">
            How'd You Discover VER?
          </InputLabel>
          <Select
            name="origin"
            labelId="origin-select-label"
            id="origin-select"
            value={originValue}
            label="How'd You Discover VER?"
            onChange={handleOriginChange}
          >
            <MenuItem value={"Repeat Customer"}>Repeat Customer</MenuItem>
            <MenuItem value={"Google Search"}>Google Search</MenuItem>
            <MenuItem value={"Bing Search"}>Bing Search</MenuItem>
            <MenuItem value={"Referral"}>Referral</MenuItem>
          </Select>
          <FormHelperText>{originHelperText}</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="company"
            id="yourCompany"
            label="Your Company (Optional)"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="phone"
            id="yourPhone"
            label="Your Phone # (Optional)"
            variant="outlined"
          />
        </FormControl>
        <Box textAlign={"center"}>
          <Button type="submit" variant="contained" color="primary">
            Continue
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default FormUpdateEventData1;

export async function action({ request, params }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const data = await request.formData();

  for (var entry of data.entries()) {
    console.log(entry[0] + ": " + entry[1]);
  }

  //normalize the size from the slider value to an iterpretable player range (should match up with the "sliderValueToSizeRange" constant array above)
  const eventData = {
    type: data.get("type"),
    size: sliderValueToSizeRange[data.get("size")][0],
    origin: data.get("origin"),
    company: data.get("company"),
    phone: data.get("phone"),
  };

  const method = "POST";
  const body = JSON.stringify(eventData);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/UpdateEventData1/?eventId=${params.eventId}`,
    context
  );

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json({ message: "could not update event", status: 500 });
  }

  const resData = await response.json();

  return redirect("/event/" + resData.eventId); // I am redirecting to the same page we were at so that I effectively refresh the data presented
}
