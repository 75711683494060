const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function RetrievePlayerData({ params }) {
  const response = await fetch(
    `${API_ENDPOINT}/api/RetrievePlayerData/?eventId=${params.eventId}&playerId=${params.playerId}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch PlayerData data for eventId = ${params.eventId} and playerId = ${params.playerId}`,
      status: 500,
    };
  }
  return response.json();
}
