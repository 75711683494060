import { redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function CreateTeamIdValidationMissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    teamName: formData.get("teamName"),
  };
  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/CreateFeedback/`, context);

  if (!response.ok) {
    throw { message: "Could not create Feedback", status: 500 };
  } else {
    return redirect(
      "/escapin-not-stirred/" + post.eventId + "/" + params.teamId + "/leader/2"
    );
  }
}
