import React, { useState, useEffect } from 'react';
import './Timer.css'

const Timer = (props) => {
    /* ----------------------------------------------------- */
    /* ----------------------TIMER-------------------------- */
    /* ----------------------------------------------------- */
    /* This is the "tick" timer functionality */

    const [num, setNum] = useState(0); /* declare a useState that uses a dummy variable "num" as the basis dependency for useEffect B that will re-render the Timer component with an updated props.accumulatedSeconds*/

    /* useEffect A */
    useEffect(() => { 
        function incrementNum() {
          setNum(prev => prev + 1);
        }
        let interval = null;
        interval = setInterval(() => {
            incrementNum()
        }, 1000);
        return () => clearInterval(interval);
    }, []); /* this useEffect has an empty dependency array, and will therefore only fire once when the Leader5 (gameboard) component/route loads. 
    This sets a repeated interval update of the num dummy function every 1 second, and each time that update happens, the useEffect B below will
    trigger and change the timerTime state variable by invoking setTimerTime with a fresh Date.now(). This works BECAUSE the Timer component has its
    own useEffect with the props as a dependency, and in the Leader5 component I invoke the Timer component with a variable props (timerTime, which is
    changing every second, and thus rerendering the Timer component with the fresh props every second) */

    /* useEffect B */
    useEffect(() => { 
        setElapsedTime(calcElapsedTime((Date.now()/1000) - props.accumulatedSeconds));  // This useEffect will actually update the display by rerendering the Timer component each time num is incremented by 1 in the useEffect A above
    }, [num]);
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */

    const calcElapsedTime = (seconds) => {
        let elapsed_secs = ''; 
        let elapsed_mins = '';
        let elapsed_hrs = '';
        let elapsed_time = '';
        elapsed_hrs = Math.floor(seconds/3600);
        if (elapsed_hrs == 0) {
            elapsed_hrs = '00';
        }
        if (elapsed_hrs < 10 && elapsed_hrs !=0) {
            elapsed_hrs = '0'+elapsed_hrs;
        }
        if (elapsed_hrs == '00') {
            elapsed_mins = Math.floor(seconds/60);
        }
        else {
            elapsed_mins = Math.floor(seconds/60) - 60*parseInt(elapsed_hrs, 10);
        }
        if (elapsed_mins == 0) {
            elapsed_mins = '00';
        }
        if (elapsed_mins < 10 && elapsed_mins !=0) {
            elapsed_mins = '0'+elapsed_mins;
        }
        if (elapsed_mins == '00' && elapsed_hrs =='00') {
            elapsed_secs = seconds;
        }
        else {
            elapsed_secs = seconds - 60*parseInt(elapsed_mins, 10) - 3600*parseInt(elapsed_hrs, 10);
        }
        elapsed_secs = Math.floor(elapsed_secs);
        if (elapsed_secs < 10) {
            elapsed_secs = '0'+elapsed_secs;
        }
        elapsed_time = elapsed_hrs+':'+elapsed_mins+':'+elapsed_secs;
        return elapsed_time;
      };

      const [elapsedTime, setElapsedTime] = useState();

  return (
    <div>{elapsedTime}</div>
  );
};

export default Timer;
