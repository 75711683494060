import { redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function UpdatePlayerData2MissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    playerId: params.playerId,
    playerRole: formData.get("playerRole"),
  };
  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/UpdatePlayerData2/`,
    context
  );

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw {
      message:
        "Could not update PlayerData playerRole for playerId = " +
        response.playerId,
      status: 500,
    };
  }

  const playerData = await response.json();
  let role = "";
  if (playerData.playerRole === "Squad Leader") {
    role = "leader";
  } else {
    role = "sleuther";
  }
  return redirect(
    "/mission-escape/" +
      params.eventId +
      "/" +
      params.playerId +
      "/" +
      role +
      "/1"
  );
}
