import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Link } from "react-router-dom";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import ExtensionTwoToneIcon from "@mui/icons-material/ExtensionTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";

export default function Nav() {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      showLabels
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "secondary.light",
      }}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="About"
        icon={<InfoTwoToneIcon fontSize="large" />}
        component={Link}
        to="/"
      />
      <BottomNavigationAction
        label="Games"
        icon={<ExtensionTwoToneIcon fontSize="large" />}
        component={Link}
        to="games"
      />
      {/* <BottomNavigationAction
        label="Pricing"
        icon={<MonetizationOnTwoToneIcon fontSize="large" />}
        component={Link}
        to="pricing"
      /> */}
    </BottomNavigation>
  );
}
