import styled from "styled-components";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmsIcon from "@mui/icons-material/Sms";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import { Popper } from "@mui/material";

const ShareContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 100%;
  outline: none;
  border: 2px solid ${({ background }) => background};
  padding: 8px;
  transform: ${({ isActive }) => (isActive ? "scale(0.8)" : "scale(1.0)")};
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: white;
    color: ${({ background }) => background};
  }
`;

const SocialLink = styled(ShareButton)`
  position: absolute;
  z-index: 0;
  transform: none;
  transition: top 0.2s ${({ position }) => `${position * 50}ms`},
    left 0.2s ${({ position }) => `${position * 50}ms`};
  left: ${({ isActive, position }) =>
    isActive ? `${(-1) ** position * Math.ceil(position / 2) * 50}px` : "0"};
  top: ${({ isActive }) => (isActive ? `50px` : "0")};
  box-shadow: ${({ isActive }) => (isActive ? `0 4px 10px 0 gray` : `0`)};
`;

const ShareModule = (props) => {
  const [menuActive, setMenuActive] = useState(false);
  const handleToggleMenu = () => {
    setMenuActive((menuActive) => !menuActive);
  };

  let socials = [];
  if (props.emailSubject != null) {
    socials.push({
      outlet: "Email",
      href:
        "mailto:?subject=" + props.emailSubject + "&body=https://" + props.url,
      background: "#CD7F32",
      color: "white",
      label: "Share via Email",
      icon: <MailOutlineIcon />,
    });
  }
  if (props.sms) {
    socials.push({
      outlet: "SMS",
      href: "sms:?:body=" + props.url,
      background: "#03C03C",
      color: "white",
      label: "Share via SMS",
      icon: <SmsIcon />,
    });
  }
  if (props.linkedIn) {
    socials.push({
      outlet: "LinkedIn",
      href: "https://www.linkedin.com/shareArticle?url=" + props.url,
      background: "#0a66c2",
      color: "white",
      label: "Share on LinkedIn",
      icon: <LinkedInIcon />,
    });
  }
  if (props.facebook) {
    socials.push({
      outlet: "Facebook",
      href: "https://www.facebook.com/sharer.php?u=" + props.url,
      background: "#3b5898",
      color: "white",
      label: "Share on Facebook",
      icon: <FacebookIcon />,
    });
  }
  if (props.pinterest) {
    socials.push({
      outlet: "Pinterest",
      href: "https://www.pinterest.com/pin/create/button/?url=" + props.url,
      background: "#D3212D",
      color: "white",
      label: "Share on Pinterest",
      icon: <PinterestIcon />,
    });
  }
  if (props.instagram) {
    socials.push({
      outlet: "Instagram",
      href: props.url,
      background: "#f201ca",
      color: "white",
      label: "Share on Instagram",
      icon: <InstagramIcon />,
    });
  }
  if (props.twitter) {
    socials.push({
      outlet: "Twitter",
      href: "https://twitter.com/intent/tweet?url=" + props.url,
      background: "#00aced",
      color: "white",
      label: "Share on Twitter",
      icon: <TwitterIcon />,
    });
  }
  // WhatsApp URL: https://api.whatsapp.com/send/?text=
  // Reddit URL: https://www.reddit.com/submit?url=
  const socialLinks = socials.map((social, index) => {
    return (
      <SocialLink
        as="a"
        href={social.href}
        target="_blank"
        rel="noreferrer"
        aria-label={social.label}
        role="button"
        isActive={menuActive}
        background={social.background}
        color={social.color}
        position={index}
        key={index}
      >
        {social.icon}
      </SocialLink>
    );
  });

  return (
    <>
      <ShareContainer>
        <ShareButton
          isActive={menuActive}
          aria-label="Share Button"
          aria-expanded={menuActive}
          role="button"
          background="#242424"
          color="white"
          onClick={handleToggleMenu}
        >
          <ShareIcon />
        </ShareButton>
        {socialLinks}
      </ShareContainer>
    </>
  );
};

export default ShareModule;
