import { redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function UpdateTeamData1MissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    teamId: params.teamId,
    teamMembers: formData.get("teamMembers"),
  };

  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/UpdateTeamData1/?eventId=${params.eventId}&teamId=${params.teamId}`,
    context
  );

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw { message: "Could not update Team Data", status: 500 };
  }

  return redirect(
    "/mission-escape/" +
      params.eventId +
      "/" +
      params.playerId +
      "/leader/" +
      params.teamId +
      "/3"
  );
}
