import React, { useState } from "react";
import { Button, Box, FormControl, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { Form, useOutletContext, useActionData } from "react-router-dom";
import LockedInfo from "../components/LockedInfo";

const Begin2MissionEscape = () => {
  const eventData = useOutletContext();
  const [step, setStep] = useState(1);
  const incrementStep = (e) => {
    setStep(step + 1);
  };
  const handleNavigation = (e) => {
    setStep(step - 1);
  };
  const data = useActionData();
  let playerEmailError = false;
  let playerEmailHelperText = "";
  if (data && data.errors.playerEmail) {
    playerEmailError = true;
    playerEmailHelperText = data.errors.playerEmail;
  }
  if (eventData.unlocked) {
    return (
      <AnimatePresence>
        <motion.div animate={{ delay: 3 }}>
          {step === 1 && (
            <Dialog open={true}>
              <DialogTitle>
                <Typography>Incoming message...</Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Form method="post">
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        name="playerEmail"
                        error={playerEmailError}
                        helperText={playerEmailHelperText}
                        id="yourEmail"
                        label="Your Email"
                        variant="outlined"
                      />
                    </FormControl>
                    <Button type="submit">Submit</Button>
                  </Form>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          )}
          {step == 2 && (
            <Dialog open={true}>
              <DialogTitle>
                <Typography>Incoming message...</Typography>
              </DialogTitle>
              <Form method="post">
                <DialogContent>
                  <DialogContentText>
                    Here's a movie about roles!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleNavigation}>Contine</Button>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </motion.div>
      </AnimatePresence>
    );
  } else {
    return <LockedInfo eventData={eventData} />;
  }
};

export default Begin2MissionEscape;
