import { redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function CreateAttemptData3MissionEscape({ request, params }) {
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    playerName: formData.get("playerName"),
    playerEmail: formData.get("playerEmail"),
  };
  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/CreatePlayerData/`,
    context
  );

  if (!response.ok) {
    throw { message: "Could not create PlayerData", status: 500 };
  } else {
    return redirect(
      "/mission-escape/" + post.eventId + "/" + response.playerId + "/leader/2"
    );
  }
}
