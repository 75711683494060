import React, { useState, useRef, Fragment } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link, Form, useLoaderData, redirect } from "react-router-dom";
import { createTeamInfo } from "../../_util/api";
import "./Styles.css";
import { retrieveTeamInfo } from "../../_util/api";
import useSound from "use-sound";
import puzzle1Intro from "../media/puzzle1Intro.mp3";
import introVideo from "../media/introVideo.mp4";
import phoneIcon from "../media/phoneIcon.png";

const Leader4 = () => {
  const vidRef = useRef(null);

  const loaderData = useLoaderData();
  const myAppRef = document.querySelector(".scrollable-div");

  const [answerButtonVisible, setAnswerButtonVisible] = useState(true);
  const [callVideoVisible, setCallVideoVisible] = useState(false);

  const [playPuzzle1IntroSound] = useSound(puzzle1Intro);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setAnswerButtonVisible(false);
    setCallVideoVisible(true);
  };
  const handleStartGameClick = (event) => {
    playPuzzle1IntroSound();
  };

  return (
    <Fragment>
      <Offcanvas
        direction="bottom"
        backdrop={false}
        fade={false}
        isOpen={true}
        className="video-offcanvas bg-console"
      >
        <OffcanvasHeader></OffcanvasHeader>
        <OffcanvasBody>
          <Container>
            <Row className="align-items-center">
              <Col
                className={`text-center ${answerButtonVisible ? "" : "hidden"}`}
              >
                <h1 className={`txt-white incoming-call-flicker`}>
                  INCOMING CALL...
                </h1>
                <button
                  style={{
                    backgroundImage: `url(${phoneIcon})`,
                  }}
                  className={`mt-5 answer-call-button appear-5`}
                  onClick={handlePlayVideo}
                ></button>
                <h1 className={`text-white appear-5`}>
                  ACCEPT<br></br>CALL
                </h1>
              </Col>
              <Col
                className={`text-center ${answerButtonVisible ? "hidden" : ""}`}
              >
                <Form method="post">
                  <button
                    className={`start-game-button appear-20`}
                    onClick={handleStartGameClick}
                  >
                    Start Game
                  </button>
                  <video
                    className={`${callVideoVisible ? "" : "hidden"}`}
                    ref={vidRef}
                    controls
                    width="80%"
                    preload="auto"
                    src={introVideo}
                    type="video/mp4"
                  >
                    Sorry, your browser doesn't support embedded videos. Please
                    use Chrome, Firefox, Edge, or Safari.
                  </video>
                  <br></br>
                </Form>
              </Col>
            </Row>
          </Container>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Leader4;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}

export async function action({ params }) {
  const post = {
    Event_ID: params.event_id,
    Team_ID: params.team_id,
  };
  const team_update_results = await createTeamInfo(post);
  return redirect(
    "/escapin-not-stirred/leader5/" +
      post.Event_ID +
      "/" +
      post.Team_ID +
      "/gameboard"
  );
}
