import React, { useState, useEffect } from "react";
import { RetrieveLeaderboard } from "../../_util/api";
import { useLoaderData, useParams, useRevalidator } from "react-router-dom";
import Timer from "../../_components/Timer";
import { Table } from "reactstrap";
import { usePusher } from "../../_context/PusherContext";

const DetailedLeaderboard = (props) => {
  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  const loaderData = useLoaderData();

  const refresher = useRevalidator();

  const params = useParams();

  useEffect(() => {
    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      // if (
      //   data["eventId"] === params.event_id
      //   // data["push_type"] === "leaderboard_update"
      // ) {
      console.log("this should reload the DETAILED leaderboard...");
      refresher.revalidate();
      console.log("after DETAILED leaderboard theoretical reload");
      // }
    });
    return () => {
      pusher.unsubscribe("ver_pusher");
    };
    // PUSHER /////////
  }, []);

  let rank = 0;
  const getRank = (prev_rank) => {
    rank++;
    return rank;
  };

  const DisplayData = loaderData.map((info) => {
    if (info.Completion_Time != null) {
      return (
        <tr key={Math.random().toString()}>
          <th className="text-center" scope="row">
            {getRank(rank)}
          </th>
          <td className="text-center">{info.Team_Name}</td>
          <td className="text-center">{info.Team_ID}</td>
          <td className="text-center">{info.Team_Members}</td>
          <td className="text-center">COMPLETE!</td>
          <td className="text-center">{info.Elapsed_Time}</td>
          <td className="text-center">
            {info.Total_Hints_Used} [{info.No_Attempt}]
          </td>
          <td className="text-center">
            {info.Total_Incorrect_Responses} [{info.Incorrect_Response_Time}]
          </td>

          <td className="text-center">{info.Score_Time}</td>
        </tr>
      );
    } else {
      return (
        <tr key={Math.random().toString()}>
          <th className="text-center" scope="row">
            {getRank(rank)}
          </th>
          <td className="text-center">{info.Team_Name}</td>
          <td className="text-center">{info.Team_ID}</td>
          <td className="text-center">{info.Team_Members}</td>
          <td className="text-center">{info.Question_ID}</td>
          <td className="text-center">
            <Timer accumulatedSeconds={info.Elapsed_Time} />
          </td>
          <td className="text-center">
            {info.Total_Hints_Used} [{info.No_Attempt}]
          </td>
          <td className="text-center">
            {info.Total_Incorrect_Responses} [{info.Incorrect_Response_Time}]
          </td>
          <td className="text-center">
            <Timer accumulatedSeconds={info.Accumulated_Seconds} />
          </td>
        </tr>
      );
    }
  });

  return (
    <Table dark>
      <thead>
        <tr>
          <th className="text-center">Rank</th>
          <th className="text-center">Team</th>
          <th className="text-center">ID</th>
          <th className="text-center">Members</th>
          <th className="text-center">Level</th>
          <th className="text-center">Elapsed Time</th>
          <th className="text-center">Hints Used [Minutes Added]</th>
          <th className="text-center">Incorrect Responses [Minutes Added]</th>

          <th className="text-center">Score</th>
        </tr>
      </thead>
      <tbody>{DisplayData}</tbody>
    </Table>
  );
};

export default DetailedLeaderboard;

export function loader({ params }) {
  return RetrieveLeaderboard(params.event_id);
}
