import * as React from "react";
import CardGameTalktales from "../components/CardGameTalktales";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

import desertedIslandEscapeIcon from "../components/desertedIslandEscapeIcon.png";

const TalktalesAdmin = () => {
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CardGameTalktales
                game="Escapin' Not Stirred"
                badge={desertedIslandEscapeIcon}
                emailSubject="Escapin' Not Stirred: Virtual Escape Room Adventure"
                title="ESCAPIN' NOT STIRRED"
                titleTypographyProps={{ variant: "h4" }}
                video={true}
                playerUrl="https://youtu.be/X7938J621lI"
                description="Sip and solve your way to victory in this riotously entertaining hybrid virtual event."
                reviews={[
                  "We had an amazing time, so much fun! Our host was great, such a fun and different way to spend time with the team!",
                  "I'm not usually a huge escape room fan but this was fun and so creative done in a virtual way! awesome job!",
                  "Very good event",
                  "Paul was an amazing host to our holiday event. Our team had a lot of fun working through the challenges.",
                  "The team had so much fun, even when we got stuck trying to count together! We shared lots of laughs and in the end we escaped haha!",
                ]}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TalktalesAdmin;
