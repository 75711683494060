import { useLoaderData, Outlet } from "react-router-dom";

const EventData = () => {
  const eventData = useLoaderData();
  return (
    <>
      <Outlet context={eventData} />
    </>
  );
};

export default EventData;
