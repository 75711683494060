import { useLoaderData, Outlet, useOutletContext } from "react-router-dom";

const PlayerData = () => {
  const eventData = useOutletContext();
  const playerData = useLoaderData();
  return (
    <>
      <Outlet context={[eventData, playerData]} />
    </>
  );
};

export default PlayerData;
