import React, { useState, useEffect, Fragment } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import {
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Form, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import "./Styles.css";
import { retrieveTeamInfo, createTeamSolution } from "../../_util/api";
import Timer from "../../_components/Timer";
import { Outlet } from "react-router-dom";
import useSound from "use-sound";
import Image from "react-bootstrap/Image";
import { motion, AnimatePresence, useAnimationControls } from "framer-motion";
import InstructionText from "../components/InstructionText";
import riddle1 from "../media/riddle1.png";
import riddle2 from "../media/riddle2.png";
import riddle3 from "../media/riddle3.png";
import riddle4 from "../media/riddle4.png";
import wrongoVillain from "../media/wrongoVillain.mp3";
import correctResponse from "../media/correctResponse.mp3";
import hintModalRevealSound from "../media/hintModalRevealSound.mp3";
import buyHintSound from "../media/buyHintSound.mp3";
import puzzle3Intro from "../media/puzzle3Intro.mp3";

const Leader6 = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === "submitting";
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const params = useParams();
  const myAppRef = document.getElementById("nefarious-villain-console"); // this is for the typewriter component

  const [playWrongo] = useSound(wrongoVillain);
  const [playCorrect] = useSound(correctResponse);
  const [playHint] = useSound(hintModalRevealSound);
  const [playBuyHint] = useSound(buyHintSound);

  const [timerTime, setTimerTime] = useState(loaderData.Accumulated_Seconds);
  const [revealHint, setRevealHint] = useState(false);
  const [hintInstructions, setHintInstructions] = useState();
  const [hint1Display, setHint1Display] = useState("");
  const [hint2Display, setHint2Display] = useState("");
  const [hint3Display, setHint3Display] = useState("");
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [playPuzzle3IntroSound] = useSound(puzzle3Intro);

  const yearIncorrectHandler = () => {
    document
      .getElementById("yearAttemptDigit1")
      .classList.remove("challenge-2-incorrect");
    document
      .getElementById("yearAttemptDigit2")
      .classList.remove("challenge-2-incorrect");
    document
      .getElementById("yearAttemptDigit3")
      .classList.remove("challenge-2-incorrect");
    document
      .getElementById("yearAttemptDigit4")
      .classList.remove("challenge-2-incorrect");
    setIsIncorrect(false);
  };

  useEffect(() => {
    if (actionData != null) {
      console.log("useEffect actionData...");
      if (actionData.origin == "year_attempt") {
        if (actionData.result == "True") {
          playCorrect();
          playPuzzle3IntroSound();
          navigate(
            "/escapin-not-stirred/leader7/" +
              loaderData.Event_ID +
              "/" +
              loaderData.Team_ID +
              "/gameboard"
          );
        } else {
          setIsIncorrect(true);
          setTimerTime(actionData.result["accumulated_seconds"]);
          playWrongo();
        }
      } else if (actionData.origin == "hint") {
        if (loaderData.Hint_ID == "0") {
          playBuyHint();
          setHint1Display(actionData.result["hint1"]);
          document.getElementById("hint-timer").classList.add("hint-flicker1");
          setTimerTime(actionData.result["accumulated_seconds"]);
        } else if (loaderData.Hint_ID == "1") {
          playBuyHint();
          setHint1Display(actionData.result["hint1"]);
          setHint2Display(actionData.result["hint2"]);
          document.getElementById("hint-timer").classList.add("hint-flicker2");
          setTimerTime(actionData.result["accumulated_seconds"]);
        } else {
          playBuyHint();
          setHint1Display(actionData.result["hint1"]);
          setHint2Display(actionData.result["hint2"]);
          setHint3Display(actionData.result["hint3"]);
          document.getElementById("hint-timer").classList.add("hint-flicker3");
          setTimerTime(actionData.result["accumulated_seconds"]);
        }
      }
    }
  }, [loaderData, actionData]);

  const clickHandler = () => {
    setRevealHint(!revealHint);
    playHint();
  };

  useEffect(() => {
    let hint_instructions = "";
    if (loaderData.Hint_ID == "0") {
      hint_instructions =
        "HINTS USED: 0/3 Are you sure you'd like to buy a hint? If you click Yes, it'll cost you 2 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else if (loaderData.Hint_ID == "1") {
      hint_instructions =
        "HINTS USED: 1/3 Are you sure you'd like to buy another hint? If you click Yes, it'll cost you 3 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else if (loaderData.Hint_ID == "2") {
      hint_instructions =
        "HINTS USED: 2/3 Are you sure you'd like to buy another hint? If you click Yes, it'll cost you 5 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else {
      hint_instructions =
        "HINTS USED: 3/3 There are no more hints available to buy. Read through the ones you've been given and you'll be able to solve this challenge.";
    }
    setHintInstructions(hint_instructions);
  }, [loaderData]);

  return (
    <Fragment>
      <Container fluid={true} style={{ backgroundColor: "#333232" }}>
        <Row>
          <Col md="8" className="ver-top-row text-white">
            <motion.div
              initial={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.1, duration: 0.5 }}
            >
              <InstructionText typeText="Aren't you so smart? I have to admit I'm somewhat impressed. But what have you learned? You'll have to  guess the four-digit passcode that will unlock your next clue. This time, wrong answers will cost you 2 MINUTES. See if you can make order out of these pictures." />
            </motion.div>
          </Col>
          <Col
            md="4"
            className="ver-top-row text-center text-white background-gray"
          >
            <Row>
              <Col>
                <h1
                  className="p-1"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 15,
                    fontSize: 60,
                    color: "#0bdf08",
                  }}
                >
                  <Timer accumulatedSeconds={timerTime} />
                </h1>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                className="text-center"
                style={{
                  borderRadius: 15,
                  boxShadow: "5px 5px black",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <h3 className="mb-0">Team ID:</h3>
                <motion.h1
                  whileHover={{
                    scale: 1.1,
                    textShadow: "0px 0px 8px rgb(255,255,255)",
                  }}
                  className="mt-0"
                >
                  {loaderData.Team_ID}
                </motion.h1>
              </Col>
              <Col>
                <button
                  className="instruction-text__button"
                  onClick={clickHandler}
                >
                  Hint
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center text-center text-white">
          <Col md="8" className="ver-bottom-row">
            <Row className={`cocktail-height`}>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle1}
                />
              </Col>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle2}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form
                  className="text-center year-input-height"
                  method="post"
                  action={`/escapin-not-stirred/leader6/${params.event_id}/${params.team_id}/gameboard`}
                >
                  <input
                    maxLength={1}
                    type="text"
                    name="yearAttemptDigit1"
                    id="yearAttemptDigit1"
                    className={`text-center challenge-2-input ${
                      isIncorrect ? "challenge-2-incorrect" : ""
                    }`}
                    onChange={yearIncorrectHandler}
                  />
                  <input
                    maxLength={1}
                    type="text"
                    name="yearAttemptDigit2"
                    id="yearAttemptDigit2"
                    className={`text-center challenge-2-input ${
                      isIncorrect ? "challenge-2-incorrect" : ""
                    }`}
                    onChange={yearIncorrectHandler}
                  />
                  <input
                    maxLength={1}
                    type="text"
                    name="yearAttemptDigit3"
                    id="yearAttemptDigit3"
                    className={`text-center challenge-2-input ${
                      isIncorrect ? "challenge-2-incorrect" : ""
                    }`}
                    onChange={yearIncorrectHandler}
                  />
                  <input
                    maxLength={1}
                    type="text"
                    name="yearAttemptDigit4"
                    id="yearAttemptDigit4"
                    className={`text-center challenge-2-input ${
                      isIncorrect ? "challenge-2-incorrect" : ""
                    }`}
                    onChange={yearIncorrectHandler}
                  />
                  <br></br>
                  <button
                    id="btn_year_attempt"
                    className={`mt-2 instruction-text__button`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "..." : "Submit"}
                  </button>
                </Form>
              </Col>
            </Row>
            <Row className={`cocktail-height`}>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle3}
                />
              </Col>
              <Col xs="6">
                <Image
                  className={`cocktail-height`}
                  fluid={true}
                  src={riddle4}
                />
              </Col>
            </Row>
          </Col>
          <Col md="4" className="ver-bottom-row scroll-x overflow-y-scroll">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Offcanvas
        backdrop={false}
        direction="end"
        fade={false}
        scrollable
        isOpen={revealHint}
      >
        <OffcanvasHeader onClick={clickHandler}>
          <Container>
            <Row>
              <Col>
                <h3 className="instruction-text__button no-btn">Close</h3>
              </Col>
              <Col>
                <h1 id="hint-timer">
                  <Timer accumulatedSeconds={timerTime} />
                </h1>
              </Col>
            </Row>
          </Container>
        </OffcanvasHeader>
        <OffcanvasBody>
          <strong>{hintInstructions}</strong>
          <Form
            method="post"
            action={`/escapin-not-stirred/leader6/${params.event_id}/${params.team_id}/gameboard`}
            className="text-center"
          >
            <button
              id="hint"
              name="hint"
              className="instruction-text__button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "..." : "Yes"}
            </button>
          </Form>
          <h6>{hint1Display}</h6>
          <h6>{hint2Display}</h6>
          <h6>{hint3Display}</h6>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Leader6;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}

export async function action({ request, params }) {
  const formData = await request.formData();
  let origin = "";
  let promptResponse = "";
  if (formData.has("yearAttemptDigit1")) {
    origin = "year_attempt";
    promptResponse =
      formData.get("yearAttemptDigit1") +
      formData.get("yearAttemptDigit2") +
      formData.get("yearAttemptDigit3") +
      formData.get("yearAttemptDigit4");
    console.log(promptResponse);
    let x = 0;
  } else if (formData.has("hint")) {
    origin = "hint";
    promptResponse = formData.get(origin);
  }
  const post = {
    Event_ID: params.event_id,
    Team_ID: params.team_id,
    Prompt_Response: promptResponse,
    Correct: origin, // I am hijacking the Correct field and using it to transmit which part of the puzzle is being responded to
  };
  const team_solution_update_results = await createTeamSolution(post);
  const actionData = {};
  actionData.origin = origin;
  actionData.result = team_solution_update_results;
  console.log(actionData);
  return actionData;
}
