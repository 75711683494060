import React from "react";
import { Form, redirect } from "react-router-dom";
import { getCookie } from "../../_util/api";
import introVideo from "../media/introVideo.mp4";

const FormUpdateTeamData2 = (props) => {
  return (
    <Form method="post">
      <video
        className={`${props.callVideoVisible ? "" : "hidden"}`}
        ref={props.vidRef}
        controls
        width="80%"
        preload="auto"
        src={introVideo}
        type="video/mp4"
      >
        Sorry, your browser doesn't support embedded videos. Please use Chrome,
        Firefox, Edge, or Safari.
      </video>
      <br></br>
      <button
        className={`start-game-button appear-20`}
        onClick={props.handleStartGameClick}
      >
        Start Game
      </button>
    </Form>
  );
};

export default FormUpdateTeamData2;

export async function action({ request, params }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const formData = await request.formData();
  const post = {
    eventId: params.eventId,
    teamId: params.teamId,
  };

  const method = "POST";
  const body = JSON.stringify(post);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/UpdateTeamData2/?eventId=${params.eventId}&teamId=${params.teamId}`,
    context
  );

  if (!response.ok) {
    throw { message: "Could not update Team Data", status: 500 };
  } else {
    return redirect(
      "/escapin-not-stirred/" + post.eventId + "/" + post.teamId + "/leader/5"
    );
  }
}
