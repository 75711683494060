import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Image from "react-bootstrap/Image";
import TypeWriterEffect from "react-typewriter-effect";
import { Form, useParams, redirect, useNavigation } from "react-router-dom";
import { createTeamInfo } from "../../_util/api";
import "./Styles.css";
import CommandPrompt from "../components/CommandPrompt";

const Leader1 = () => {
  const navigation = useNavigation();

  const isSubmitting = navigation.state === "submitting";
  const params = useParams();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div");
  return (
    <div>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="Please enter a Team Name (humor welcome... make me laugh)"
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
        </ModalBody>
        <ModalBody className="bg-console text-center">
          <Form
            className="appear-4"
            method="post"
            action={`/escapin-not-stirred/leader1/${params.event_id}`}
          >
            <input
              type="text"
              name="Team_Name"
              id="team_name"
              className="team-info-form__input text-center"
            />
            <button
              className="instruction-text__button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "..." : "Enter"}
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Leader1;

export async function action({ request, params }) {
  const formData = await request.formData();
  const post = {
    Event_ID: params.event_id,
    Team_Name: formData.get("Team_Name"),
  };
  // try {
  const team_creation_results = await createTeamInfo(post);
  // } catch (err) {
  //     if (err.status === 500) {
  //         return err;  //this prevents a redirect
  //     }
  //     throw err;
  // }
  return redirect(
    "/escapin-not-stirred/leader2/" +
      post.Event_ID +
      "/" +
      team_creation_results.Team_ID
  );
}
