import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Image from "react-bootstrap/Image";
import TypeWriterEffect from "react-typewriter-effect";
import { Form, useParams, useOutletContext, redirect } from "react-router-dom";
import { createTeamData } from "../../_util/api";
import CommandPrompt from "../components/CommandPrompt";
import FormCreateTeamData from "../components/FormCreateTeamData";

const Leader1MissionEscape = () => {
  const eventData = useOutletContext();
  const params = useParams();
  const [modal, setModal] = useState(true);
  const myAppRef = document.querySelector(".scrollable-div");
  return (
    <div>
      <Modal isOpen={modal} fade={false} size={"lg"}>
        <CommandPrompt />
        <ModalBody className="bg-console modal-height">
          <div className="instruction-text__item">
            C:\Users\nefarious\villain
          </div>
          <div className="instruction-text__item">
            <TypeWriterEffect
              startDelay={200}
              cursorColor="white"
              text="Please enter a Team Name (humor welcome... make me laugh)"
              typeSpeed={40}
              scrollArea={myAppRef}
              textStyle={{
                fontWeight: 500,
                fontSize: "1.9em",
              }}
            />
          </div>
        </ModalBody>
        <ModalBody className="bg-console text-center">
          <FormCreateTeamData />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Leader1MissionEscape;
