import React, { useState, useEffect, Fragment } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { Form, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import "./Styles.css";
import { retrieveTeamInfo, createTeamSolution } from "../../_util/api";
import Timer from "../../_components/Timer";
import { Outlet } from "react-router-dom";
import useSound from "use-sound";
import Image from "react-bootstrap/Image";
import { usePusher } from "../../_context/PusherContext";
import hintModalRevealSound from "../media/hintModalRevealSound.mp3";
import { motion, AnimatePresence, useAnimationControls } from "framer-motion";
import InstructionText from "../components/InstructionText";

const Sleuther4 = () => {
  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const params = useParams();
  const myAppRef = document.getElementById("nefarious-villain-console"); // this is for the typewriter component

  const [playHint] = useSound(hintModalRevealSound);

  const [timerTime, setTimerTime] = useState(loaderData.Accumulated_Seconds);
  const [revealHint, setRevealHint] = useState(false);
  const [hintInstructions, setHintInstructions] = useState();
  const [hint1Display, setHint1Display] = useState("");
  const [hint2Display, setHint2Display] = useState("");
  const [hint3Display, setHint3Display] = useState("");
  const [isIncorrect, setIsIncorrect] = useState(false);

  const yearIncorrectHandler = () => {
    document
      .getElementById("passwordAttempt")
      .classList.remove("challenge-2-incorrect");
    setIsIncorrect(false);
  };

  const clickHandler = () => {
    setRevealHint(!revealHint);
    playHint();
  };

  useEffect(() => {
    let hint_instructions = "";
    if (loaderData.Hint_ID == "0") {
      hint_instructions =
        "HINTS USED: 0/3 Are you sure you'd like to buy a hint? If you click Yes, it'll cost you 2 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else if (loaderData.Hint_ID == "1") {
      hint_instructions =
        "HINTS USED: 1/3 Are you sure you'd like to buy another hint? If you click Yes, it'll cost you 3 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else if (loaderData.Hint_ID == "2") {
      hint_instructions =
        "HINTS USED: 2/3 Are you sure you'd like to buy another hint? If you click Yes, it'll cost you 5 MINUTES. There is no guarantee that you haven't already figured out the information that the hint will give, but it may end up cracking the puzzle wide open. Buying a hint may be a great strategy that ultimately saves you time, or it might put you behind the competition. Choose wisely...";
    } else {
      hint_instructions =
        "HINTS USED: 3/3 There are no more hints available to buy. Read through the ones you've been given and you'll be able to solve this challenge.";
    }
    setHintInstructions(hint_instructions);
  }, [loaderData]);

  useEffect(() => {
    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      console.log(
        data["event_id"] +
          ":" +
          loaderData.Event_ID +
          ",   " +
          data["team_id"] +
          ":" +
          loaderData.Team_ID
      );
      if (
        data["event_id"] === loaderData.Event_ID.toString() &&
        data["team_id"] == loaderData.Team_ID.toString()
      ) {
        if (data["push_type"] === "sleuther_reroute") {
          // This occurs when this team's Team Leader submits a correct response
          console.log("Pusher sessionID = " + pusher["sessionID"].toString());
          navigate(data["url"]);
        }
      }
    });
    // PUSHER /////////
  }, []);

  return (
    <Fragment>
      <Container fluid={true} style={{ backgroundColor: "#333232" }}>
        <Row>
          <Col md="8" className="ver-top-row text-white">
            <motion.div
              initial={{ scale: 0.1 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.1, duration: 0.5 }}
            >
              <InstructionText typeText="You continue to impress - you must want that next cocktail pretty badly. Your final challenge is in the form of a video. See if you can make sense of the one word password that will unlock your mixologist and allow them to meet you in the virtual meeting lobby. Oh but this time, wrong answers will cost you 10 minutes." />
            </motion.div>
          </Col>
          <Col
            md="4"
            className="ver-top-row text-center text-white background-gray"
          >
            <Row>
              <Col>
                <h1
                  className="p-1"
                  style={{
                    backgroundColor: "black",
                    borderRadius: 15,
                    fontSize: 60,
                    color: "#0bdf08",
                  }}
                >
                  {/* <Timer accumulatedSeconds={timerTime} /> */}
                </h1>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col
                className="text-center"
                style={{
                  borderRadius: 15,
                  boxShadow: "5px 5px black",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                <h3 className="mb-0">Team ID:</h3>
                <motion.h1
                  whileHover={{
                    scale: 1.1,
                    textShadow: "0px 0px 8px rgb(255,255,255)",
                  }}
                  className="mt-0"
                >
                  {loaderData.Team_ID}
                </motion.h1>
              </Col>
              <Col>
                <button
                  className="instruction-text__button"
                  onClick={clickHandler}
                >
                  Hint
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center text-center text-white">
          <Col md="8" className="ver-bottom-row">
            <Row>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://drive.google.com/file/d/1Ds73xEI1QmzvBlOImWxhVdIVJbzjWPSX/preview"
                  width="640"
                  height="359.781"
                  allow="autoplay"
                  frameborder="0"
                ></iframe>
              </div>
            </Row>
          </Col>
          <Col md="4" className="ver-bottom-row scroll-x overflow-y-scroll">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Offcanvas
        backdrop={false}
        direction="end"
        fade={false}
        scrollable
        isOpen={revealHint}
      >
        <OffcanvasHeader onClick={clickHandler}>
          <Container>
            <Row>
              <Col>
                <h3 className="instruction-text__button no-btn">Close</h3>
              </Col>
              <Col>
                <h1 id="hint-timer">
                  <Timer accumulatedSeconds={timerTime} />
                </h1>
              </Col>
            </Row>
          </Container>
        </OffcanvasHeader>
        <OffcanvasBody>
          <strong>{hintInstructions}</strong>
          <h6>{hint1Display}</h6>
          <h6>{hint2Display}</h6>
          <h6>{hint3Display}</h6>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Sleuther4;

export function loader({ params }) {
  return retrieveTeamInfo(params.event_id, params.team_id);
}
