import React, { useState } from "react";
import { Button, Box, FormControl, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { Form, useOutletContext, useActionData } from "react-router-dom";
import LockedInfo from "../components/LockedInfo";

const Begin1MissionEscape = () => {
  const eventData = useOutletContext();
  const [step, setStep] = useState(1);
  const incrementStep = (e) => {
    setStep(step + 1);
  };

  const actionData = useActionData();

  let playerNameError = false;
  let playerNameHelperText = "";

  if (actionData && actionData.errors.playerName) {
    playerNameError = true;
    playerNameHelperText = actionData.errors.playerName;
  }

  if (eventData.unlocked) {
    return (
      <AnimatePresence>
        <motion.div animate={{ delay: 3 }}>
          {step === 1 && (
            <Dialog open={true}>
              <DialogTitle>
                <Typography>Incoming call...</Typography>
              </DialogTitle>

              <DialogContent>
                <DialogContentText>placeholder</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Form method="post">
                  <Button onClick={incrementStep}>Anser Call</Button>
                </Form>
              </DialogActions>
            </Dialog>
          )}
          {step === 2 && (
            <Dialog open={true}>
              <DialogTitle>
                <Typography>Incoming message...</Typography>
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  {eventData.eventId}
                  Let Google help apps determine location. This means sending
                  anonymous location data to Google, even when no apps are
                  running.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Form method="post">
                  <Button onClick={incrementStep}>Continue</Button>
                </Form>
              </DialogActions>
            </Dialog>
          )}
          {step === 3 && (
            <Dialog open={true}>
              <DialogTitle>
                <Typography>Incoming message...</Typography>
              </DialogTitle>
              <Form method="post">
                <DialogContent>
                  <DialogContentText>
                    Please enter your name, Agent
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        name="playerName"
                        error={playerNameError}
                        helperText={playerNameHelperText}
                        id="yourName"
                        label="Your Name"
                        variant="outlined"
                      />
                    </FormControl>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button type="submit">Submit</Button>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </motion.div>
      </AnimatePresence>
    );
  } else {
    return <LockedInfo eventData={eventData} />;
  }
};

export default Begin1MissionEscape;
