import React, { useState, useEffect } from "react";
import { RetrieveLeaderboard } from "../../_loaders/RetrieveLeaderboard";
import { useLoaderData, useParams, useRevalidator } from "react-router-dom";
import Timer from "../../_components/Timer";
import { Table } from "reactstrap";
import { usePusher } from "../../_context/PusherContext";

const DetailedLeaderboard = (props) => {
  const pusher = usePusher(); // Use the custom pusher hook to get the pusher instance from context

  const loaderData = useLoaderData();

  const refresher = useRevalidator();

  const params = useParams();

  useEffect(() => {
    // PUSHER /////////
    const channel = pusher.subscribe("ver_pusher");
    channel.bind("escapin_not_stirred", (data) => {
      if (
        data["eventId"] === params.eventId &&
        data["push_type"] === "leaderboard_update"
      ) {
        refresher.revalidate();
      }
    });
    return () => {
      pusher.unsubscribe("ver_pusher");
    };
    // PUSHER /////////
  }, []);

  let rank = 0;
  const getRank = (prev_rank) => {
    rank++;
    return rank;
  };

  const DisplayData = loaderData.map((info) => {
    if (info.Completion_Time != null) {
      return (
        <tr key={Math.random().toString()}>
          <th className="text-center" scope="row">
            {getRank(rank)}
          </th>
          <td className="text-center">{info.teamName}</td>
          <td className="text-center">{info.teamMembers}</td>
          <td className="text-center">COMPLETE!</td>
          <td className="text-center">{info.elapsedTime}</td>
          <td className="text-center">{info.totalHintsUsed}</td>
          <td className="text-center">{info.hintTime}</td>
          <td className="text-center">{info.totalIncorrectResponses}</td>
          <td className="text-center">{info.incorrectResponseTime}</td>
          <td className="text-center">{info.scoreTime}</td>
        </tr>
      );
    } else {
      return (
        <tr key={Math.random().toString()}>
          <th className="text-center" scope="row">
            {getRank(rank)}
          </th>
          <td className="text-center">{info.teamName}</td>
          <td className="text-center">{info.teamMembers}</td>
          <td className="text-center">{info.Question_ID}</td>
          <td className="text-center">{info.elapsedTime}</td>
          <td className="text-center">{info.totalHintsUsed}</td>
          <td className="text-center">{info.hintTime}</td>
          <td className="text-center">{info.totalIncorrectResponses}</td>
          <td className="text-center">{info.incorrectResponseTime}</td>
          <td className="text-center">
            <Timer accumulatedSeconds={info.accumulatedSeconds} />
          </td>
        </tr>
      );
    }
  });

  return (
    <Table dark>
      <thead>
        <tr>
          <th className="text-center">Rank</th>
          <th className="text-center">Team</th>
          <th className="text-center">Members</th>
          <th className="text-center">Level</th>
          <th className="text-center">Elapsed Time</th>
          <th className="text-center">Hints Used</th>
          <th className="text-center">Hint Minutes Added</th>
          <th className="text-center">Incorrect Responses</th>
          <th className="text-center">Incorrect Response Minutes Added</th>
          <th className="text-center">Score</th>
        </tr>
      </thead>
      <tbody>{DisplayData}</tbody>
    </Table>
  );
};

export default DetailedLeaderboard;

export function loader({ params }) {
  return RetrieveLeaderboard(params.eventId);
}
