import { json } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function CreateTeamData(teamData) {
  const method = "POST";
  const body = JSON.stringify(teamData);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/CreateTeamData/`, context);

  if (!response.ok) {
    throw { message: "Could not create Team Info", status: 500 };
  } else {
    return response.json();
  }
}

export async function createTeamIDVerification(teaminfo) {
  const method = "POST";
  const body = JSON.stringify(teaminfo);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(
    `${API_ENDPOINT}/api/create-team-id-validation/`,
    context
  );

  if (!response.ok) {
    throw { message: "Server-side error validationg Team ID", status: 500 };
  } else {
    return response.json();
  }
}

export async function CreateAttemptData(attemptData) {
  const method = "POST";
  const body = JSON.stringify(attemptData);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/create-solution/`, context);

  if (!response.ok) {
    throw json(
      { message: "Could not create Team Solution data" },
      { status: 500 }
    );
  } else {
    return response.json();
  }
}

export async function retrieveEventInfo(event_id) {
  const response = await fetch(
    `${API_ENDPOINT}/api/retrieve-event/?Event_ID=${event_id}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch EventInfo data for event_id = ${event_id}`,
      status: 500,
    };
  }
  return response.json();
}

export async function retrieveTeamInfo(event_id, team_id) {
  const response = await fetch(
    `${API_ENDPOINT}/api/retrieve-team/?Event_ID=${event_id}&Team_ID=${team_id}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch TeamInfo data for team_id = ${team_id}`,
      status: 500,
    };
  }
  return response.json();
}

export async function RetrieveLeaderboard(event_id) {
  const response = await fetch(
    `${API_ENDPOINT}/api/retrieve-leaderboard/?Event_ID=${event_id}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch Leaderboard data for Event ID = ${event_id}`,
      status: 500,
    };
  }
  return response.json();
}
export async function retrieveLeaderboard(event_id) {
  const response = await fetch(
    `${API_ENDPOINT}/api/retrieve-leaderboard/?Event_ID=${event_id}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch Leaderboard data for Event ID = ${event_id}`,
      status: 500,
    };
  }
  return response.json();
}

export async function retrieveTeamInfoAndLeaderboard(event_id, team_id) {
  const response1 = await fetch(
    `${API_ENDPOINT}/api/retrieve-team/?Event_ID=${event_id}&Team_ID=${team_id}`
  );
  if (!response1.ok) {
    throw {
      message: `Failed to fetch TeamInfo data for team_id = ${team_id}`,
      status: 500,
    };
  }
  const response2 = await fetch(
    `${API_ENDPOINT}/api/retrieve-leaderboard/?Event_ID=${event_id}`
  );
  if (!response2.ok) {
    throw {
      message: `Failed to fetch Leaderboard data for Event ID = ${event_id}`,
      status: 500,
    };
  }
  return { TeamInfo: response1.json(), Leaderboard: response2.json() };
}

export async function createTeamInfo(teaminfo) {
  // if (teaminfo.Team_Name === 'scrotum') {
  //     throw { message: 'Don\'t be a potty mouth', status: 422 };
  // }

  const method = "POST";
  const body = JSON.stringify(teaminfo);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/create-team/`, context);

  if (!response.ok) {
    throw { message: "Could not create Team Info", status: 500 };
  } else {
    return response.json();
  }
}

export async function createTeamSolution(solutioninfo) {
  const method = "POST";
  const body = JSON.stringify(solutioninfo);
  const csrftoken = getCookie("csrftoken");
  const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    context = {
      method: method,
      body: body,
      headers: headers,
    };
  const response = await fetch(`${API_ENDPOINT}/api/create-solution/`, context);

  if (!response.ok) {
    throw { message: "Could not create Team Info", status: 500 };
  } else {
    return response.json();
  }
}

export async function uploadFile(fileinfo) {
  const formData = new FormData();
  formData.append("user-file", fileinfo, "user-file.jpg");
  const response = await fetch(`${API_ENDPOINT}/api/upload-file/`, {
    method: "POST",
    body: formData,
  });
  if (!response.ok) {
    throw { message: "Could not upload the file", status: 500 };
  } else {
    return response.json();
  }
}

// https://docs.djangoproject.com/en/3.2/ref/csrf/
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
