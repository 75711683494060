import React from "react";
import { ListItem, ListItemIcon, Typography, Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const ReviewItem = (props) => {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <StarIcon color="gold" />
          <StarIcon color="gold" />
          <StarIcon color="gold" />
          <StarIcon color="gold" />
          <StarIcon color="gold" />
        </ListItemIcon>
        <Typography paddingLeft={2}>{props.review}</Typography>
      </ListItem>
      <Divider />
    </>
  );
};

export default ReviewItem;
