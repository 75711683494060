const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function RetrieveEventData({ params }) {
  const response = await fetch(
    `${API_ENDPOINT}/api/RetrieveEventData/?eventId=${params.eventId}`
  );
  if (!response.ok) {
    throw {
      message: `Failed to fetch EventData data for eventId = ${params.eventId}`,
      status: 500,
    };
  }
  return response.json();
}
