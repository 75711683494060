import React from "react";
import ReactPlayer from "react-player";
import "./Player.css";

const Player = (props) => {
  if (props.playing === true) {
    return (
      <>
        <div className="player-wrapper">
          <ReactPlayer
            url={props.url}
            className="react-player"
            playing
            muted
            width="100%"
            height="100%"
            controls={props.controls}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="player-wrapper">
          <ReactPlayer
            url={props.url}
            className="react-player"
            width="100%"
            height="100%"
            controls={props.controls}
          />
        </div>
      </>
    );
  }
};

export default Player;
