import React, { useState, useRef, Fragment } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useOutletContext } from "react-router-dom";
import useSound from "use-sound";
import FormUpdateTeamData2 from "../components/FormUpdateTeamData2";
import phoneIcon from "../media/phoneIcon.png";
import puzzle1Intro from "../media/puzzle1Intro.mp3";

const Leader4MissionEscape = () => {
  const vidRef = useRef(null);

  const allData = useOutletContext();
  const eventData = allData[0];
  const teamData = allData[1];
  const myAppRef = document.querySelector(".scrollable-div");

  const [answerButtonVisible, setAnswerButtonVisible] = useState(true);
  const [callVideoVisible, setCallVideoVisible] = useState(false);

  const puzzle1IntroSoundURL = puzzle1Intro;
  const [playPuzzle1IntroSound] = useSound(puzzle1IntroSoundURL);

  const handlePlayVideo = () => {
    vidRef.current.play();
    setAnswerButtonVisible(false);
    setCallVideoVisible(true);
  };
  const handleStartGameClick = (event) => {
    playPuzzle1IntroSound();
  };

  return (
    <Fragment>
      <Offcanvas
        direction="bottom"
        backdrop={false}
        fade={false}
        isOpen={true}
        className="video-offcanvas bg-console"
      >
        <OffcanvasHeader></OffcanvasHeader>
        <OffcanvasBody>
          <Container>
            <Row className="align-items-center">
              <Col
                className={`text-center ${answerButtonVisible ? "" : "hidden"}`}
              >
                <h1 className={`text-white incoming-call-flicker`}>
                  INCOMING CALL...
                </h1>
                <button
                  style={{
                    backgroundImage: `url(${phoneIcon})`,
                  }}
                  className={`mt-5 answer-call-button appear-5`}
                  onClick={handlePlayVideo}
                ></button>
                <h1 className={`text-white appear-5`}>
                  ACCEPT<br></br>CALL
                </h1>
              </Col>
              <Col
                className={`text-center ${answerButtonVisible ? "hidden" : ""}`}
              >
                <FormUpdateTeamData2
                  callVideoVisible={callVideoVisible}
                  vidRef={vidRef}
                  handleStartGameClick={handleStartGameClick}
                />
              </Col>
            </Row>
          </Container>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default Leader4MissionEscape;
